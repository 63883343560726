import React, {FC, useEffect} from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import background from "./img/baby_bg.jpg";
import logo from "./img/wake_up_safe_logo.png";
import './css/landingpage.css'
import { useSessionStorage } from './useSessionStorage';
import Card from 'react-bootstrap/Card';
import { version_num, dashboard_url } from "./config";

const LandingPage: FC = () => {
    const [surveyPayload, setSurveyPayload] = useSessionStorage('survey',null);
    const [caseSubmitFlag, setCaseSubmitFlag] = useSessionStorage('case_submit_flag',false);
    const [surveyUUID, setSurveyUUID] = useSessionStorage('survey_uuid',null);
    useEffect(()=>{
        setCaseSubmitFlag(false);
        setSurveyPayload({});
        setSurveyUUID(null);
        // console.log()
    },[]);
  

    return (
        <div id="cards_landscape_wrap-2" style={{ backgroundImage:`url(${background})` }}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-4" style={{ padding: 40 }}>
                        <a href="">
                            <Link to="/select_clinical_events">
                            <div className="card-flyer">
                                <div className="text-box">

                                    <div className="image-box">
                                        <img src={require('./img/submit.png')} alt="" />
                                    </div>
                                    <div className="text-container">
                                         <h6>Submit New Event</h6>
                                        <p></p>

                                    </div>

                                </div>
                            </div>
                            </Link>
                        </a>
                    </div>

                    <div className="col-xs-12 col-md-4" style={{ padding: 40 }}>
                        
                            <div className="card-flyer">
                                <div className="text-box pb2">
                                    <div className="image-box">
                                        <img src={require('./img/review.jpeg')} alt="" />
                                    </div>
                                    <div className="text-container">
                                    <h6>Review </h6>
                                        <div className="w-100 mv3">
                                        <a href="" className="grow dib fl w-48">
                                                <Link to="/review_cases">
                                                <span className=" br2 bg-mint-important white f45 b pa2">Submitted Cases</span>
                                                </Link>
                                        </a>
                                        <a href="" className="grow dib fr w-48">
                                                <Link to="/review_working_cases">
                                                <span className="br2 bg-mint-important white f45 b pa2">Working Cases</span>
                                                </Link>
                                            </a>    
                                        </div>
                                    </div>

                                </div>
                            </div>
                        
                    </div>
                    <div className="col-xs-12 col-md-4" style={{ padding: 40 }}>
                        <a href={dashboard_url} target='_blank'>
                                <div className="card-flyer">
                                    <div className="text-box">

                                        <div className="image-box">
                                            <img src={require('./img/dashboard.png')} alt="" />
                                        </div>
                                        <div className="text-container">
                                            <h6>Summary Statistics Dashboard</h6>
                                        </div>
                                    </div>
                                </div>
                        </a>
                    </div>
                </div>
                {/* <div className="row">

                </div> */}

                {/* <div className="row justify-content-center align-items-center opacity-50"  style={{ top: '5%',position: 'absolute', left:'5%'}} >
                    <img src={logo} alt="Logo" style={{width: 250, height: 80}} />
                </div> */}

            </div>

            <Card>
                <Card.Body>The Wake Up Safe Case Reporting system ({version_num})  is powered by the Children's Hospital of Philadelphia.</Card.Body>
            </Card>

        </div>



    );
}

export default LandingPage;