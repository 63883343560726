import React from "react";

import Select , { createFilter } from "react-select";

import { RendererFactory } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionCheckbox } from "survey-react-ui";

import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import CustomOption from './CustomSelectOptions';
import CustomMenuList from "./VirtualList";
import "survey-core/modern.css";
import "./index.css";
import { json } from "./json"


// function removePunctuation(s){
//   var punctuationless = s.replaceAll('-'," ");
//   // var finalString = punctuationless.replace(/  +/g, ' ');
//   // return finalString;
//   return punctuationless
// }

StylesManager.applyTheme("modern");
// var customStyles;
class CustomSelect extends SurveyQuestionCheckbox {
  // Convert the `visibleChoices` array to a format supported by React Select
  get options() {
    return this.question.visibleChoices.map((c) => {
      return { value: c.value, label: c.value };
    });
  }
  // Retrieve an option object based on the question value
  get selectedOption() {
    const res = [];
    const opts = this.options;
    let qValue = this.question.value;
    if (!qValue) qValue = [];
    for (var i = 0; i < opts.length; i++) {
      if (qValue.indexOf(opts[i].value) > -1) res.push(opts[i]);
    }
    return res;
  }
  // Set the question value to the selected option value
  onSelectChange = (selectedOption) => {
    const newValue = [];
    for (var i = 0; i < selectedOption.length; i++) {
      newValue.push(selectedOption[i].value);
    }
    this.question.value = newValue;
  };

  filterOption = (candidate, input) => {
    if(input){
      
      // setTimeout(console.log("INPUT",check_input),2500)
      input = input.replaceAll('-',' ')
      var label = candidate.label.replaceAll('-',' ')
      var match =  label.match(new RegExp(input, 'i'));
      // if(candidate.label.includes('self-harm')){
      //   console.log(candidate.label.replaceAll('-',''))
      // }
      if (!match){
        return false
      }
      else{
        return true
      }
    }
    return true;
    
  };
  
  customStyles = {
    // menu: (provided, state) => ({
    //   ...provided,
    //   width: state.selectProps.width,
    //   borderBottom: '1px dotted pink',
    //   color: state.selectProps.menuColor,
    //   padding: 20,
    // }),
  
    // control: (_, { selectProps: { width }}) => ({
    //   width: width
    // }),
  
    option: (provided, state) => {
      // const opacity = state.isDisabled ? 0.5 : 1;
      // const transition = 'opacity 300ms';
      const padding = "2px 7px 0px 7px";
  
      return { ...provided, padding};
    }
  }
  
  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
<div id={this.question.inputId}
     className={this.question.getControlClass}
     disabled>
    {this.question.displayValue || this.question.optionsCaption}
</div>
      );
    }
    return (
<Select id={this.question.inputId}
        styles={this.customStyles}
        filterOption={this.filterOption}
        // filterOption={createFilter({ ignoreAccents: false, stringify: option=> `${option.label}` })}
        components={{ Option: CustomOption,MenuList: CustomMenuList }}
        value={this.selectedOption}
        onChange={this.onSelectChange}
        options={this.options}
        isMulti={true}
        required={this.question.isRequired}
        menuPortalTarget={document.querySelector("body")} />
    );
  }
}
// Register the CustomSelect component as a renderer under a custom name "sv-tagbox-react"
ReactQuestionFactory.Instance.registerQuestion(
  "sv-tagbox-react",
  (props) => {
    return React.createElement(CustomSelect, props);
  }
);
// Set up a custom component that will render React Select
// Register "sv-tagbox-react" as a renderer for questions whose `type` is "tagbox" and `renderAs` property is "tagbox-react"
RendererFactory.Instance.registerRenderer(
  "checkbox",
  "tagbox-react",
  "sv-tagbox-react"
);

function SurveyComponent() {
    const survey = new Model(json);
// survey.data = { car: ['Ford'] };

    return (<Survey model={survey} />);
}

export default SurveyComponent;
