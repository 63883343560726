import React, {FC, useState} from "react";
import "./css/tachyons.min.css"
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { v5 as uuidv5 } from 'uuid';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { email_service, service_url, mailto_list } from "./config";
import 'bootstrap/dist/css/bootstrap.min.css';




const sendImages = (uploadedFiles: FileList | null, support_uuid: string = "test") => {
  if (uploadedFiles) {
    const formData = new FormData();

    var totalfiles = uploadedFiles.length;
    for (var index = 0; index < uploadedFiles.length; index++) {
      formData.append("file", uploadedFiles[index]);
    }
    formData.append("uuid",support_uuid)
    console.log(formData)
    return axios.post(email_service + '/upload_screenshots', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
      }
    })
   
  }
  return 
}
const SupportForm = (props:any) => {
  const [title,setTitle] = useState('')
  const [type, setType] = useState('clinical_support')
  const [description,setDescription] = useState('')
  
  return (
    <Form>
      <Form.Group className="mb3" controlId="title">
        <Form.Label className="fl b">Title</Form.Label>
        <Form.Control type="text" placeholder="What is the issue?" value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
        
      </Form.Group>
      <Form.Group className="mb3" controlId="type">
      <Form.Label className="fl b">Type</Form.Label>
        <Form.Select aria-label="Select Type" value={type} onChange={(e) => { setType(e.target.value) }}>
        <option value="clinical_support">Clinical Support</option>
        <option value="tech_support">Technical Support</option>
        <option value="admin_support">Administrative Support</option>
        <option value="other_support">Other</option>
        </Form.Select>
        </Form.Group>
      <Form.Group className="mb3" controlId="Please describe the issue in detail">
        <Form.Label className="fl b">Description</Form.Label>
        <Form.Control as="textarea" rows={5} placeholder="What is the issue?" value={description} onChange={(e) => { setDescription(e.target.value) }}/>
      </Form.Group>
      <Form.Group className="mb3" controlId="Please describe the issue in detail">
      <Form.Label className="fl b">Upload Screenshots (PNG, JPEG, PDF are allowed)</Form.Label>
        <Form.Control type="file" accept=".png,.jpeg,.jpg,.pdf" multiple onChange={(e: any) => props.upload_files(e.target.files)}/>
      </Form.Group>
      <br/><br/>
      <Button variant="primary" onClick={(e)=>{props.submitHandler(e,{"title":title,"description":description, "issue_type":type})}}>
        Submit
      </Button>
    </Form>
  );
}

const HelpDesk: FC = ()=>{
    const [uploadedImages, setImages] = useState<FileList | null>(null)
    const namespace = '1b0db098-ef29-468a-98be-40d58c91b2c0'
    let user_name = sessionStorage.getItem("user_name") || ''
    let user_id = sessionStorage.getItem("user_id") || ''
    let user_email = sessionStorage.getItem("user_email")|| ''
    let history = useHistory();

  const alert_function = (type: string, uuid: string = '') => {

    let message = "Please try again or <a href="+{ mailto_list }+" target='_blank'>email support</a>"
    let title = "Something went wrong"
    let allowOutsideClick = true
    if (type === 'upload') {
      message = "Unable to send the uploaded images to the server<br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a><br>"
    }
    else if (type === 'email') {
      message = "Unable to send the email with the ticket number to you<br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a>"
    }
    else if (type === 'submit') {
      message = "Unable to submit your ticket to WUS support <br><br>\
      Please try again or <a href="+ { mailto_list } +" target='_blank'>email support</a>"
    }
    else if (type === 'success') {
      title = "Ticket Submitted"
      message = " Please keep a copy of this ticket number <br> \
                <div id='bar'><b>"+ uuid + "</b></div>"
        allowOutsideClick = false
    }

    Swal.fire({
      title: title,
      icon: type === "success" ? "success" : "error",
      html: message,
      allowOutsideClick: allowOutsideClick
    }).then((result) => {
      if(type === "success"){
        history.push("/")
      }
    })
  }
    const submit = (e:any,formData:any)=>{
      document.body.style.cursor = 'progress'
      let post_data= formData
      post_data['user_name'] = user_name
      post_data['status'] = 'Open'
      // post_data['ticket_uuid'] = sp_map[formData['type']]+user_name+Date.now()
      post_data['ticket_uuid'] = uuidv5(user_id +'_ticket_'+Date.now(), namespace)
      console.log(post_data)
      console.log(email_service)
      var config = {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      };

      let email_post_data = post_data
      email_post_data["user_email"]=user_email
      axios.post(service_url + '/submit_ticket', post_data, config).then(
        (response) => {
          alert_function("success", post_data['ticket_uuid'])
          document.body.style.cursor = 'default'
        }, (error) => {
          alert_function("submit")
          document.body.style.cursor = 'default'
      })
      if (uploadedImages)
      {
        sendImages(uploadedImages, post_data['ticket_uuid'])?.then((image_response) => {
          axios.post(email_service + '/sendTicketEmail', email_post_data, config).then(
            (email_response) => {
            },(email_error)=>{
              console.log(email_error)
          })
        },(error)=>{
          console.log(error)
          
        })
      }
      else{
        axios.post(email_service + '/sendTicketEmail', email_post_data, config).then(
          (email_response) => {
            console.log("email sent")
          }, (email_error) => {
            // alert_function("email")
            document.body.style.cursor = 'default'
          })
      }
    }

    return (
    <div className="fl w-60">
        <h2>Wake Up Safe Support</h2>
        <SupportForm submitHandler={submit} upload_files = {setImages} />
    </div>
    )
}
export default HelpDesk;