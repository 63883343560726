import React, {FC} from "react";
import * as Survey from "survey-react";
import axios from "axios";
import ReactDOM from "react-dom";
import { composeJSON } from "./survey_templates/SurveyComposer";

const Contributory: FC = props =>   {
    let json = composeJSON(null);

    Survey
        .StylesManager
        .applyTheme("modern");

    var survey = new Survey.Model(json);

    survey
        .onComplete
        .add(function (sender: any) {
            // @ts-ignore
            // document.documentElement.querySelector('#surveyResult').textContent="Result JSON:\n" + JSON.stringify(sender.data, null, 3);

            // send data to backend database
            var config = {
                headers: {'Access-Control-Allow-Origin': '*'}
            };
            axios.post('http://localhost:5001/', sender.data, config)
                .then(function(response){
                    console.log(response);
                    //Perform action based on response
                })
                .catch(function(error){
                    console.log(error);
                    //Perform action based on error
                });
            // route to clinical events selection page
            // window.location.href = "/select_clinical_events";
        });

    function doOnCurrentPageChanged() {

        // @ts-ignore
        var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');

        for(var i=0; i< elemets.length; i++){
            var div = elemets[i];
            div.setAttribute("style","font-size:13px");
        }
    }

    ReactDOM.render(<Survey.Survey model={survey}/>, document.getElementById("root"), function(){doOnCurrentPageChanged()});
    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default Contributory;