import React from 'react';
import ReactDOM from 'react-dom';
import AppWUS from './AppWUS';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Authenticator } from '@aws-amplify/ui-react';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  // <React.StrictMode>
  //   <BrowserRouter>
  //   <App />
  //   </BrowserRouter>
  // </React.StrictMode>,
  <Authenticator.Provider>
  <BrowserRouter>
  <React.Fragment>
  <App />
  </ React.Fragment>
  </BrowserRouter>
  </Authenticator.Provider>,
  document.getElementById('root')
  );




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
