import React from "react";

import Select from "react-select";

import { RendererFactory } from "survey-core";
import { ReactQuestionFactory, SurveyQuestionCheckbox } from "survey-react-ui";

import { StylesManager, FunctionFactory, Serializer } from "survey-core";

import "survey-core/modern.css";


StylesManager.applyTheme("modern");


function sqroot(value) {
    return Math.sqrt(value).toPrecision(2)
}
FunctionFactory.Instance.register("sqroot", sqroot);
class CustomSelect extends SurveyQuestionCheckbox {
    // Convert the `visibleChoices` array to a format supported by React Select
    
    get options() {
        return this.question.visibleChoices.map((c) => {
            return { value: c.value, label: c.value };
        });
    }
    // Retrieve an option object based on the question value
    get selectedOption() {
        const res = [];
        const opts = this.options;
        let qValue = this.question.value;
        if (!qValue) qValue = [];
        for (var i = 0; i < opts.length; i++) {
            if (qValue.indexOf(opts[i].value) > -1) res.push(opts[i]);
        }
        return res;
    }
    // Set the question value to the selected option value
    onSelectChange = (selectedOption) => {
        const newValue = [];
        for (var i = 0; i < selectedOption.length; i++) {
            newValue.push(selectedOption[i].value);
        }
        this.question.value = newValue;
    };
    renderElement() {
        // If the question is non-editable, render a stylized div
        if (this.isDisplayMode) {
            return (
                <div id={this.question.inputId}
                     className={this.question.getControlClass()}
                     disabled>
                    {this.question.displayValue || this.question.optionsCaption}
                </div>
            );
        }
        return (
            <Select id={this.question.inputId}
                    value={this.selectedOption}
                    onChange={this.onSelectChange}
                    options={this.options}
                    isMulti={true}
                    required={this.question.isRequired}
                    menuPortalTarget={document.querySelector("body")} />
        );
    }
}

// Register the CustomSelect component as a renderer under a custom name "sv-tagbox-react"
ReactQuestionFactory.Instance.registerQuestion(
    "sv-tagbox-react",
    (props) => {
        return React.createElement(CustomSelect, props);
    }
);
// Set up a custom component that will render React Select
// Register "sv-tagbox-react" as a renderer for questions whose `type` is "tagbox" and `renderAs` property is "tagbox-react"
RendererFactory.Instance.registerRenderer(
    "checkbox",
    "tagbox-react",
    "sv-tagbox-react"
);

function IntegerValidator(params) {
    var value = params[0];
    // if(typeof(JSON.parse()))
    if(value){

        // if (isNaN(value)){
        //     return false;
        // }
        // console.log(typeof(JSON.parse(value)))
        return Number.isInteger(value);
    }
    else{

        return true;
    }
  }

FunctionFactory.Instance.register("IntegerValidator",IntegerValidator);

function getJSON(surveyName, jsonDir){
        let json = {}

        switch(surveyName){
            case "eye":
                json = require(jsonDir+'Eye.json');
                break;

            case "airway":
                json = require(jsonDir+'Airway.json');
                break;

            case "cardiac":
                json = require(jsonDir+'Cardiac.json');
                break;

            case "neurology":
                json = require(jsonDir+'Neurologic.json');
                break;

            case "psych":
                json = require(jsonDir+'Psychological.json');
                break;

            case "dental":
                json = require(jsonDir+'Dental.json');
                break;

            case "meta":
                json = require(jsonDir+'Metabolic.json');
                break;

            case "musculoskeletal":
                json = require(jsonDir+'Musculoskeletal.json');
                break;

            case "misc":
                json = require(jsonDir+'Miscellaneous.json');
                break;

            case "demo":
                json = require(jsonDir+"Demographics.json");
                for(let item_idx in json["pages"][0]["elements"]){
                    // console.log("ITEM",item_idx)
                    if (json["pages"][0]["elements"][item_idx]["name"]==="45e48b52-7e40-5b70-a949-2e7149d2376e"){
                        let icd10_codes = require(jsonDir+"ICD10_codes.json")['codes']
                        json["pages"][0]["elements"][item_idx]["choices"] = icd10_codes
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="93a08765-9793-5934-a866-40b48b8fbefa"){
                        let cpt_codes = require(jsonDir+"CPT_Codes.json")['codes']
                        json["pages"][0]["elements"][item_idx]["choices"] = cpt_codes
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="2add8390-8531-5c44-8c0a-e7d5926d902f"){
                        // json["pages"][0]["elements"][item_idx]["max"] =
                        let current_year =  new Date().getFullYear()
                        json["pages"][0]["elements"][item_idx]["validators"] = [
                            {
                              "type": "expression",
                              "text": "Input should be an Integer",
                              "expression": "IntegerValidator({2add8390-8531-5c44-8c0a-e7d5926d902f}) = true"
                            },
                            {
                                "type": "expression",
                                "text": "Value should not be less than 2020",
                                "expression": "{2add8390-8531-5c44-8c0a-e7d5926d902f}=='' or {2add8390-8531-5c44-8c0a-e7d5926d902f} > 2019 "
                            },
                            {
                                "type": "expression",
                                "text": "Value should not be more than " + current_year ,
                                "expression": "{2add8390-8531-5c44-8c0a-e7d5926d902f}=='' or {2add8390-8531-5c44-8c0a-e7d5926d902f} <= " + current_year
                            },
                          ]
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="7a0b666d-ffe5-54a9-8cff-226cacfa05ae"){
                        json["pages"][0]["elements"][item_idx]["validators"] = [
                            {
                              "type": "expression",
                              "text": "Input should be an Integer",
                              "expression": "IntegerValidator({7a0b666d-ffe5-54a9-8cff-226cacfa05ae}) = true"
                            }
                          ]
                    }
                    if (json["pages"][0]["elements"][item_idx]["name"]==="8f13f86d-fb5c-504c-bac8-f195d598ef93"){
                        
                        json["pages"][0]["elements"][item_idx]["validators"]=[
                            {
                                "type": "expression",
                                "text":"Year should be between 0 and 100",
                                "expression": " ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} == '') or ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} >= 0) and ({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889} <= 100)"
                            },
                            {
                                "type": "expression",
                                "text":"Month should be between 0 and 11",
                                "expression": " ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} == '') or  ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} >= 0) and ({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8} < 12)"
                            },
                            {
                                "type": "expression",
                                "text": "Year should be an Integer",
                                "expression": "IntegerValidator({8f13f86d-fb5c-504c-bac8-f195d598ef93.69a5473f-271f-5bef-bddf-3663304e2889}) = true"
                              },
                              {
                                "type": "expression",
                                "text": "Month should be an Integer",
                                "expression": "IntegerValidator({8f13f86d-fb5c-504c-bac8-f195d598ef93.86ae2d05-c5fa-5308-880e-009a5823b6c8}) = true"
                              }
                        ];
                    }

                }
                break;
            case "contributory":
                json = require(jsonDir+"Safety.json");
                break;

            default:
                alert("Can not find your specified survey.");
        };

        return json;

    }

export function pagesJSON(newElements){
    const jsonDir = "./";
    var new_pages=[];
    newElements.forEach((element) => {
        let elementJSON = getJSON(element, jsonDir);
        new_pages.push(elementJSON['pages'][0]);
    });
    return new_pages;

}
export function composeJSON(selectedElements){

        const jsonDir = "./";

        const elements = selectedElements;
        let surveyJSON = {
            "title": "Survey",
            "logo": "./wake_up_safe_logo.png",
            "logoWidth": 200,
            "logoHeight": 90,
            "description": "Demographics",
            "progressBarType": "buttons",
            "showProgressBar": "top",
            "pages": []
        };

        let demoJSON = getJSON("demo", jsonDir);
        surveyJSON['pages'].push(demoJSON['pages'][0]);
        elements.forEach((element) => {
            let elementJSON = getJSON(element, jsonDir);
            surveyJSON['pages'].push(elementJSON['pages'][0]);
        });
        let contributoryJSON = getJSON("contributory", jsonDir);
        surveyJSON['pages'].push(contributoryJSON['pages'][0]);
        surveyJSON["checkErrorsMode"]="onValueChanged"
        surveyJSON["triggers"]= [
            {
                "type": "runexpression",
                "expression": "{c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951}!=0",
                "setToName": "71cd114e-d13e-5a82-8257-9d7ec9d726e7",
                "runExpression": " {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} / ({b1de9a34-315f-5f96-bc14-c1c8d6968951}*{b1de9a34-315f-5f96-bc14-c1c8d6968951}) "
            }
            // {
            //     "type": "runexpression",
            //     "expression": "{c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} isempty and {71cd114e-d13e-5a82-8257-9d7ec9d726e7} notempty and {b1de9a34-315f-5f96-bc14-c1c8d6968951} notempty ",
            //     "setToName": "c55a2bbb-0169-5584-a1d4-4d62f79cc9ad",
            //     "runExpression": " {71cd114e-d13e-5a82-8257-9d7ec9d726e7} * ({b1de9a34-315f-5f96-bc14-c1c8d6968951}*{b1de9a34-315f-5f96-bc14-c1c8d6968951}) "
            // },
            // {
            //     "type": "runexpression",
            //     "expression": "{b1de9a34-315f-5f96-bc14-c1c8d6968951} empty and {71cd114e-d13e-5a82-8257-9d7ec9d726e7} notempty and {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad} notempty and {c55a2bbb-0169-5584-a1d4-4d62f79cc9ad}!=0 ",
            //     "setToName": "b1de9a34-315f-5f96-bc14-c1c8d6968951",
            //     "runExpression": " sqroot({c55a2bbb-0169-5584-a1d4-4d62f79cc9ad}/{71cd114e-d13e-5a82-8257-9d7ec9d726e7}) "
            // }
        ]

        return surveyJSON;
}

