import logo from "./img/wake_up_safe_logo.png";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import React, {FC, useEffect} from "react";
import "./css/tachyons.min.css";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { user_manual_url, data_dictionary_url  } from "./config";



const NavigationBar: FC = (props:any)=>{
    let history = useHistory();
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    let username: string = '';
    let user_return = sessionStorage.getItem('user_name')
    if(user_return!=null)
    {
        username= user_return;
    }
    const logout = ()=>{
      let survey_id = sessionStorage.getItem('survey_uuid');
      console.log(survey_id);
      if(survey_id==="null"){
        // console.log("Survey not started")
        signOut();
        // history.push("/")
      }
      else{
        // console.log("Survey started but not completed")
        Swal.fire({
          icon: 'info',
          title: 'Data Loss Warning',
          text: 'You have not submitted this survey, you will lose the data for this survey if you logout',
          showConfirmButton: true,
          confirmButtonText: "Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Logout",
        }).then((result)=>{
          if(result.isDenied){
            // alert_open = false;
            signOut();
          }
        })
      }
    }

    const cusor_change = ()=>{
      document.body.style.cursor = 'default';
    }

    const goHome = ()=>{
      let survey_id = sessionStorage.getItem('survey_uuid');
      console.log(survey_id);
      if(survey_id==="null"){
        // console.log("Survey not started")
        history.push('/')
      }
      else{
        // console.log("Survey started but not completed")
        Swal.fire({
          icon: 'info',
          title: 'Data Loss Warning',
          text: 'You have not submitted this survey, you will lose the data for this survey if you continue',
          showConfirmButton: true,
          confirmButtonText: "Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Continue",
        }).then((result)=>{
          if(result.isDenied){
            // alert_open = false;
            history.push('/')
          }
        })
      }
    }
    return (
    <>
      <Navbar bg="light" className="w-100">
        {/* <Container> */}
          <Navbar.Brand href="" onClick={goHome} onMouseOver={()=>{document.body.style.cursor = 'pointer'}} onMouseOut={()=>{document.body.style.cursor = 'default'}} className="fl pl4">
            <img
              src={logo}
              width="123"
              height="37"
              className="fl"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Nav.Link className="justify-content-start ml3 " onClick={goHome}>Home</Nav.Link>
          <Nav.Link className="justify-content-start ml3 " href={user_manual_url} target="_blank">User Manual</Nav.Link>
          <Nav.Link className="justify-content-start ml3 " onClick={()=>{history.push('/support')}}>Support</Nav.Link>
          <Nav.Link className="justify-content-start ml3 " href={data_dictionary_url} target="_blank">Data Dictionary</Nav.Link>
          <Navbar.Collapse className="justify-content-end mr4">
          <Navbar.Text>
              Signed in as: <b>{username}</b>
          </Navbar.Text>
        </Navbar.Collapse>
        <Button style={{backgroundColor: "#5AC69F", borderColor: "#5AC69F"}} className="justify-content-end mr4" href="" onClick={logout}>Logout</Button>
        {/* </Container> */}
      </Navbar>
    </>
    )
}

export default NavigationBar;