// import { useSessionStorage } from "./useSessionStorage";

import Swal from "sweetalert2";
import { useRef, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useHistory } from "react-router-dom";

//UCOMMENT  WHEN NOT TESTING
export const INACTIVE_TIMER = 1.5e+6//20000// //25 MIN
export const LOGOUT_TIMER = 1.8e+6 //30 MIN
export const INTERVAL_TIME = 60000//1 MIN

//COMMENT OUT WHEN NOT TESTING
// export const INACTIVE_TIMER = 10000//10 SEC
// export const LOGOUT_TIMER = 20000// 20 SEC
// export const INTERVAL_TIME = 5000//5 SEC


const seconds2minutes = (s)=>{
  let minutes = Math.floor(s / 60);
  let seconds = s - (minutes * 60);
  if(seconds<10){
    seconds = '0'+seconds
  }
  return minutes+":"+seconds
}
var alert_open=false;
export const activityTracker = (...args)=>{
    // let history = useHistory();
    // const [timer, setTimer] = useSessionStorage('timer',null)
    // const { route } = useAuthenticator(context => [context.route]);
    let timerInterval;
    let sign_out_func = args[0]
    let auth_flag = JSON.parse(sessionStorage.getItem('auth_status'))
    // console.log("Tracker:",auth_flag)
    let curr_time = Date.now();
    //@ts-ignore
    let last_interact = JSON.parse(sessionStorage.getItem('timer'));
    let diff = curr_time-last_interact;
    let res=null;

    const timeout = ()=>{
        Swal.fire({
          icon: "info",
          title: "Logged Out",
          text: "Signed out due to inactivity",
        }).then(()=>{});
        window.clearInterval(timerInterval) 
        sign_out_func();
        // history.push("/")
    }
    if(auth_flag === 'authenticated' && !alert_open){
      if(diff>INACTIVE_TIMER){
        alert_open=true;
        Swal.fire({
          icon: 'info',
          title: 'Inactive Session',
          // text: 'You will be automatically logged out in 5 minutes',
          html: 'You will be logged out in <strong></strong> minutes.<br/><br/>' ,
          timer: LOGOUT_TIMER-INACTIVE_TIMER-100,
          didOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().getElementsByTagName('strong')[0]
                .textContent = seconds2minutes((Swal.getTimerLeft() / 1000).toFixed(0))
            }, 100)
          },
          // willClose: () => {
          //   console.log("SWAL CLOSED")
          //   window.clearInterval(timerInterval)
          // },
          didDestroy: () => {
            console.log("SWAL CLOSED")
            window.clearInterval(timerInterval)
            alert_open = false;
          },

          showConfirmButton: true,
          confirmButtonText: "No, Stay on this Page",
          showDenyButton: true,
          denyButtonText: "Logout",
        }).then((result)=>{
          window.clearInterval(timerInterval)
          console.log(result)
          if(result.isConfirmed){
            // alert_open = false;
            sessionStorage.setItem('timer',JSON.stringify(Date.now()));
          }
          else if(result.isDenied){
            // alert_open = false;
            sign_out_func();
            // history.push("/")
          }
          else if(result.isDismissed){
            if(result.dismiss==="timer"){
              timeout()
            }
            else{
              sessionStorage.setItem('timer',JSON.stringify(Date.now()));
            }

          }

        })
      }
    }
    
  };

  export const userInteractionHandler = (event,setTimer) => {
    // let curr_time = Date.now();
    console.log('Mouse moved',event);
    setTimer(Date.now());
  }
  
  
  export const useUnload = fn => {
    const cb = useRef(fn);
    useEffect(() => {
      const onUnload = cb.current;
      window.addEventListener('beforeunload', onUnload);
      return () => {
        window.removeEventListener('beforeunload', onUnload);
      };
    }, [cb]);
  };

 export const unload_handler = (e)=>{
   let status = JSON.parse(sessionStorage.getItem('auth_status'));

   if(status === 'authenticated' ){
          e.preventDefault();
          e.returnValue=''; 
      }
  }