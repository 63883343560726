import React, {FC, useEffect} from "react";
import { Redirect } from "react-router";
import "./css/App.css";
import ClinicalEvents from "./ClinicalEvents";
import ClinicalEventsSurvey from './ClinicalEventsSurvey';
import LoadClinicalEventsSurvey from './LoadClinicalEventsSurvey';
import LandingPage from "./LandingPage";
import Demographics from "./Demographics";
import Contributory from "./Contributory";
import { Switch, Route} from "react-router";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/tachyons.min.css";
import ReviewCases from "./ReviewCases";
import { useSessionStorage } from "./useSessionStorage";
import { v5 as uuidv5 } from 'uuid';
import HelpDesk from './HelpDesk';

import { useHistory } from "react-router-dom";

// import { storeWebSession, writeUser2DB } from "./utility/DbService";
import LoadSingleSurvey from "./LoadSingleSurvey";
import NavigationBar from "./NavigationBar";
import LoadEditableSurvey from "./LoadEditabelSurvey";



var loadCounter =0;


const AppWUS: FC = (props:any)=> {
  const [timer, setTimer] = useSessionStorage('timer', Date.now());
  const [webSessionID, setWebSessionID] = useSessionStorage('web_session_id',null);
  const [surveyPayload, setSurveyPayload] = useSessionStorage('survey',null);
  const [caseSubmitFlag, setCaseSubmitFlag] = useSessionStorage('case_submit_flag',false);
  // console.log("Loaded App this app")
  const [surveyUUID, setSurveyUUID] = useSessionStorage('survey_uuid',null);  
  const namespace = '1b0db098-ef29-468a-98be-40d58c91b2c0'
  let history = useHistory();

  useEffect(()=>{
      
      //Receive from the login page
      loadCounter=loadCounter+1;
      let user_id = sessionStorage.getItem("user_id")
      let user_name = sessionStorage.getItem("user_name")
      console.log("ID:",user_id, "Name:",user_name)
      let curr_time = Date.now();
      setTimer(curr_time);
      setWebSessionID(uuidv5("websession_"+user_id+"_"+curr_time,namespace));
      setCaseSubmitFlag(false);
      setSurveyPayload({});
      setSurveyUUID(null);

      return(()=>{
        history.push("/")
        sessionStorage.clear()
        // alert("Exiting");
      })
  },[]);



  
  return (
    <React.Fragment>
    <div className="App" >
      <NavigationBar />
      <header className="App-header">
          <Route exact path="/" component={LandingPage}></Route>      
          <Route exact path='/review_cases' component={() => (<ReviewCases type={"submitted"} />)}></Route>
          <Route exact path='/review_working_cases' component={() => (<ReviewCases type={"unsubmitted"} />)}></Route>
          <Route exact path="/single_case" component={LoadSingleSurvey}></Route>
          <Route exact path="/edit_single_case" component={LoadEditableSurvey}></Route>
          <Route exact path="/select_clinical_events" component={ClinicalEvents}></Route>
          <Route exact path="/clinical_events" component={ClinicalEventsSurvey}></Route>
          <Route exact path="/support" component={HelpDesk}></Route>
          <Route exact path="/contributory" component={Contributory}></Route>
          {/* <Route exact path="/load_from_storage" component={LoadClinicalEventsSurvey}></Route> */}
          <Route exact path="/demo" component={Demographics}></Route>
      </header>
    <div className="tl" id="survey_root"></div>
    </div> 
    </React.Fragment>
  );
}

export default AppWUS;