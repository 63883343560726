// export const service_url = "http://localhost:5000";
// export const email_service = "http://localhost:5002";

export const service_url = "https://wusreport.com:5001";
export const dashboard_url = "https://wusreport.com:7001";
export const email_service = "https://wusreport.com:5003";
export const user_manual_url = "https://cpt4code.s3.amazonaws.com/WUS+Case+Reporting+User+Guide-Ver1.4.pdf"
export const data_dictionary_url = "https://www.dropbox.com/scl/fi/58dmutub3635fsjey4cz0/WUS_Dictionary-ver1.2.xlsx"
export const version_num = "Ver. 1.4"
export const mailto_list = "'mailto:grovers1@chop.edu,shil2@chop.edu,wakeupsafe@gmail.com,tsuif@chop.edu'"
