import "./css/tachyons.min.css";
import { Amplify, Logger } from 'aws-amplify';
import React, { useEffect, useState } from "react";
import { Authenticator, useAuthenticator, useTheme, View, Image, Button, Link } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { SelectField, TextField } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify';
import axios from "axios";
import AppWUS from "./AppWUS";
import { useSessionStorage } from './useSessionStorage';
import { useInterval } from "./useInterval";
import { activityTracker} from "./sessionUtilities";
import { INTERVAL_TIME } from "./sessionUtilities";
import { storeWebSession} from "./utility/DbService";
import { unload_handler } from "./sessionUtilities";
import { useHistory } from "react-router-dom";
import {service_url, email_service, mailto_list} from "./config";
import { userLogoutDB } from "./utility/DbService";
import Pending from "./Pending"
import Swal from "sweetalert2";
Amplify.configure(awsExports);

Amplify.configure({storage: sessionStorage});

const logger = new Logger('WUS_log', 'DEBUG');

export default function App() {
    sessionStorage.setItem("reloading",true);
    let history = useHistory();
    var coming_from_signup=false; 
    var confirmed_email =false; // 
    var auth_listened=false;

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const { route } = useAuthenticator(context => [context.route]);
    const [approvalStatus, setApprovalStatus]  = useState('');    
    const [auth_status_var,setAuthStatus] = useSessionStorage('auth_status', '');


  useEffect(() => {
    Hub.listen('auth', (data) => {
        
         auth_listened=true;
        
        switch (data.payload.event) {
           
            case 'signIn':
                if(!confirmed_email && coming_from_signup)break; // you will see processing -- processing 
                console.log('user signed in');
                //(if signup flag then we have this information)
                let username = data.payload.data["username"]
                let attr = data.payload.data["attributes"]
                let email = attr["email"]
                let user_id = attr["sub"]
                console.log(user_id, username, email)
                var config = {
                  headers: {
                      'Access-Control-Allow-Origin': '*'
                    } 
                  };
                if(coming_from_signup){
                    // Dump data to user route in the backend
                    let dataString = {
                        "UserID": user_id, 
                        "UserName":username,
                        "Email": email,
                        "Role":sessionStorage.getItem('role'),
                        "first_name":sessionStorage.getItem('fname'),
                        "last_name":sessionStorage.getItem('lname'),
                        "institution":sessionStorage.getItem('institution'),
                        "signup_login":coming_from_signup,
                        "LoginStatus":true,
                    };
                    console.log("DS:",dataString)
                    // --- This didnt happen -- we never reached here or re-render 
                    axios.post(service_url + "/user", dataString, config)
                    .then((response) => {
                        // window.location.replace('/')
                        console.log("SETTING VARIABLES:",username,user_id);
                        sessionStorage.setItem("user_id",user_id);
                        sessionStorage.setItem("user_name",username);
                        sessionStorage.setItem("user_email", email);
                        axios.post(email_service+"/emailAdmin",{"username":username, "user_email":email},config)
                        .then((response)=>{
                          setApprovalStatus("Pending")
                        }, (error) => {
                          setApprovalStatus('Error')
                          Swal.fire({
                            icon: "error",
                            title: "Connection Error (Notify Admin)",
                            allowOutsideClick: false,
                            html: "Unable to notify the administrator. Please <a href="+{mailto_list}+" target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='outline>"+error+"</div>"
                          }).then((response) => {
                          })
                          console.log("Email ADMIN ERROR:",error);      
                        });
                    }, (error) => {
                      setApprovalStatus('Error')
                      Swal.fire({
                        icon: "error",
                        title: "Connection Error",
                        allowOutsideClick: false,
                        html: "Unable to register the user. Please <a href=" + { mailto_list } +" target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='outline black'>"+ error + "</div>"
                      }).then((response)=>{
                      })
                    console.log("USER DB Error",error);
                    });
                }
                else{
                    console.log("FE redirect only")
                    let dataString = {
                    "UserID": user_id, 
                    "UserName":username,
                    "signup_login":coming_from_signup,
                    "LoginStatus":true
                    };
                    sessionStorage.setItem("user_id", user_id);
                    sessionStorage.setItem("user_name", username);
                    sessionStorage.setItem("user_email", email);
                    axios.post(service_url + "/user", dataString, config)
                    .then((response) => {
                        console.log("Response:",response.data["status"])
                        
                        console.log("SETTING VARIABLES:",username,user_id);
                        setApprovalStatus(response.data["status"])
                        
                    }, (error) => {
                      setApprovalStatus('Error')
                      console.log(error)
                      Swal.fire({
                        icon: "error",
                        title: "Connection Error",
                        allowOutsideClick: false,
                        html: "Unable to get user details. Please <a href=" + { mailto_list } +" target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <b>"+ error +"</b>"
                      }).then((response) => {
                        
                      })
                    console.log(error);
                    });
                }
                break;
            case 'signUp':
                // console.log('user signed up'); 
                // axios.post(service_url+'/get_care_sites')
                break;
            case 'signOut':
                console.log('user signed out');
                history.push("/")
                userLogoutDB();
                break;
            case 'signIn_failure':
                // console.log('user sign in failed');
                break;
            case 'configured':
                // console.log('the Auth module is configured');
                break;
            case 'confirmSignUp':
                // console.log("Confirmation:",data.payload.data)
                if(data.payload.data ==="SUCCESS"){
                    confirmed_email = true;
                }
                coming_from_signup = true;
                break;
            case 'tokenRefresh':
                // console.log('token refresh succeeded');
                break;
            case 'tokenRefresh_failure':
                // console.log('token refresh failed');
                break;
            case 'autoSignIn':
                // console.log('Auto Sign In after Sign Up succeeded');
                break;
        }

    });

    // function press_ok_unload(){
    //   signOut();
    // }
  
    
    if(!auth_listened && sessionStorage.getItem('user_name')){
      console.log("Getting Approval Status on Refresh")
      // Get Approval Status
      var config = {
        headers: {
          'Access-Control-Allow-Origin': '*'
        } 
      };
      var dataString = {
        "UserID": sessionStorage.getItem('user_id'), 
        "UserName":sessionStorage.getItem('user_name'),
        "signup_login":false,
        "LoginStatus":true,
      };
      axios.post(service_url + "/user",dataString, config)
      .then((response) => {
        console.log("Response:",response.data["status"])
        
        setApprovalStatus(response.data["status"])
      }, (error) => {
        console.log(error);
      });

    }
    
  }, []);
  
  
  useEffect(()=>{
    
    setAuthStatus(authStatus);
  },[authStatus])
  
  
  useInterval(()=>{activityTracker(signOut)},INTERVAL_TIME);
  
  useInterval(()=>{
    storeWebSession();
  },60000);
  
  

  window.addEventListener('beforeunload', (e)=>{unload_handler(e,signOut)})
  window.addEventListener("mousemove",()=>{sessionStorage.setItem('timer',JSON.stringify(Date.now()))});
  window.addEventListener("keypress",()=>{sessionStorage.setItem('timer',JSON.stringify(Date.now()))});
  
 
  
  const components = {
    Header() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="WUS logo"
            src="./wake_up_safe_logo.png"
          />
        </View>
      );
    },
    SignIn:{
      Footer() {
        const { toSignUp, toResetPassword	 } = useAuthenticator();
        const [helpFlag, setHelpFlag] = useState(false);
        
        
          return (
            <View textAlign="center">
              <Button
                fontWeight="normal"
                onClick={toSignUp}
                size="small"
                variation="link"
              >
                New User?
              </Button>
              <Button
                fontWeight="normal"
                onClick={toResetPassword}
                size="small"
                variation="link"
              >
                Forgot Password?
              </Button>
              <br /><br />
              <Link
                fontWeight="normal"
                href = {mailto_list}
                size="small"
              >
                Contact Support
              </Link>
            </View>
            
          );
        
      },
    },
    SignUp: {
      FormFields(){
        const { validationErrors } = useAuthenticator((context) => [context.validationErrors]);
        const [careSites, setCareSites] = useState([]);
        useEffect(()=>{
          var config = {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          };
          axios.post(service_url+'/care_sites',{},config)
          .then((response)=>{
            setCareSites(response.data['result'])
          },(error)=>{
            Swal.fire({
              icon: "error",
              title: "Connection Error",
              allowOutsideClick: false,
              html: "Unable to retrieve care sites. Please <a href=" + { mailto_list } +" target='_blank'>email support</a> \
                            and send a screenshot of this error message<br><br>\
                            <div class='black'>Database Connection Error </div>"
            }).then((response) => {
            })
          })
        },[])
        if(careSites.length==0)
        {
          return (<div className="tc">Retreiving Care Sites ....</div>)
        }
        else{

          return(
            <>
              
              <TextField
                isRequired={true}
                errorMessage={validationErrors.lname}
                hasError={!!validationErrors.lname}
                key="lastname"
                name="lastname"
                placeholder="Last Name"
                onChange = {(e)=>{sessionStorage.setItem('fname',e.target.value)}} />
              <TextField
                isRequired={true}
                errorMessage={validationErrors.fname}
                hasError={!!validationErrors.fname}
                key="firstname"
                name="firstname"
                placeholder="First Name"
                onChange = {(e)=>{sessionStorage.setItem('lname',e.target.value)}}/>
              <SelectField
                isRequired={true}
                errorMessage={validationErrors.institution}
                hasError={!!validationErrors.institution}
                key='institution'
                name='institution'
                placeholder="Institution"
                onChange = {(e)=>{sessionStorage.setItem('institution',e.target.value)}}>
                {careSites.map((item,index)=>{
                  return <option key={'inst_'+item['site_id']} value={item['site_id']}>{item['site_name']}</option>
                })}
                
              </SelectField>
              <SelectField
                isRequired={true}
                errorMessage={validationErrors.role}
                hasError={!!validationErrors.role}
                key='role'
                name='role'
                placeholder="Role"
                onChange = {(e)=>{sessionStorage.setItem('role',e.target.value)}}>
                <option value="1">Administrator</option>
                <option value="2">IT</option>
                <option value="3">Provider</option>
              </SelectField>
  
              <Authenticator.SignUp.FormFields />
            </>
          )
        }
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
  
        return (
          <View textAlign="center">
            <Button
              fontWeight="normal"
              onClick={toSignIn}
              size="small"
              variation="link"
            >
              Back to Sign In
            </Button>
          </View>
        );
      },
    }
  }

  if(approvalStatus===''){

    return (
    <Authenticator
        components={components}
      >
        
      <article className="vh-50 dt w-100 flex items-center">
      <div className="flex justify-center tc w-100 ph3 ph4-l">
        Processing...
      </div>
      </article>
    
      
      </Authenticator>
    );

  }
  else{
      if (approvalStatus!=="Approved"){
        return (
          <Authenticator
            components={components}
          >
            
          <Pending status={approvalStatus}/>
        
          
          </Authenticator>
        );
      }
      else{
    
        return (
          <Authenticator
            components={components}
          >
            
          <AppWUS />
        
          
          </Authenticator>
        );
      }
      
  }
}

