import React, {FC} from "react";
import {Link} from 'react-router-dom';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';  
import ReactTooltip from 'react-tooltip';
// import { Button } from "react-bootstrap";
import "./css/tachyons.min.css"
// import Swal from "sweetalert2";


function Card(props:any) {
    // console.log("HERE",props)
    const border_background = props.selected?"ba bg-orange b--gold":""
    const border_color = props.selected?"":"dim grow ba bw1 b--black-50"
        return (
           
            <>
            <ReactTooltip id={"card-"+props.label}  className="w-30 custom-color-no-arrow" backgroundColor='#EEDC82' effect="solid" html={true}>
                {'<div class="b tl black">'+props.text+'</div>'}
            </ReactTooltip>
            <div className={"w-10 ph2 pb1 pt3 mh5 br4  "+border_background}>
                <span className="white fr f4 fw9 nt3-l nr2-l">&#10003;</span>
                <div data-tip data-for={"card-"+props.label} className={"pointer pv3 br4 bg-mint w-100 "+border_color} onClick={props.onClick} >
                <img src={props.file} className="outline bg-white br-100 pa1 h3 w3 dib" />
                <span className="db f4 ">{props.label}</span>
                </div>
            </div>
            </>
        )
        
}


class Menu extends React.Component <any,any> {
    constructor(props:any){
        super(props);
        this.state={
            selected: Array(9).fill(false),
            return_selection:[],

        }
    }

    renderCard(i:number){
        // console.log(this.props.options[i].label)
        return (
            <Card
                selected={this.state.selected[i]}
                label={this.props.options[i].label}
                file={this.props.options[i].file}
                text = {this.props.options[i].definition}
                onClick={() => this.handleCardClick(i)}
            />
        );

    }

    submitSelection(event: React.MouseEvent<HTMLButtonElement>){


        for(let i=0;i<this.state.selected.length;i++){
            if(this.state.selected[i]){
                this.state.return_selection.push(this.props.options[i]);
            }
        }
        // Preventing the page from reloading
        console.log(this.state.return_selection);
        // event.preventDefault();
    };
    handleCardClick(i: number){

        const selected = this.state.selected.slice();
        selected[i] = !this.state.selected[i];
        this.setState({
            selected: selected,
            return_selection:[],

        });
    }
    handleUnselect(){

        this.setState({
            selected: Array(9).fill(false),
            return_selection:[],

        });
    }
    render() {

        return (
            <div className="w-100 center ph3-ns">
                <div className="cf ph2-ns">
                    <div className="flex justify-center">
                        
                        {this.renderCard(0)}
                        {this.renderCard(1)}
                        {this.renderCard(2)}
                    </div>
                    <div className="mt5 flex justify-center">
                        {this.renderCard(3)}
                        {this.renderCard(4)}
                        {this.renderCard(5)}
                    </div>
                    <div className=" mt5 flex justify-center">
                        {this.renderCard(6)}
                        {this.renderCard(7)}
                        {this.renderCard(8)}
                    </div>
                </div>
                <button className="br2 pa1 mt5 mh3" onClick={()=>{this.handleUnselect()}} > Unselect All</button>


                <Link to={{
                    pathname: "/clinical_events",
                    state: this.state.return_selection
                }}><button className="br2 pa1 mt5" onClick={(e)=>{this.submitSelection(e)}}> Submit</button>
                </Link>
            </div>


        );
    }
}




const ClinicalEvents: FC = () => {
    const eye_def_html=`
    Unanticipated injury to eye or visual system after non-ocular surgery that causes 
    <ol class="f6 tl"> 
    <li>Impaired/lost vision</li>
    <li>Swelling/redness</li>
    <li>Pain </li>
    </ol>
    Common examples include 
    <ul class="f6 tl"> 
    <li>Cornea</li>
    <li>Eyelids</li>
    <li>Retina/optic nerve</li>
    <li>Intra ocular</li>
    </ul>
    `

    const airway_def_html = `An adverse event involving upper airway, lower airway, lung parenchyma, or pleura that presents with
    <ol class="f6 tl"> 
        <li>Hypoxemia</li>
        <li>Difficulty breathing</li>
        <li>Respiratory failure</li>
        <li>Pain/Hoarseness/Coughing</li>
    </ol>
    Common causes include 
     <ol class="f6 tl"> 
        <li>Laryngospasm</li>
        <li>Aspiration</li>
        <li>Airway obstruction</li>
        <li>Pulmonary edema etc.</li>
    </ol>
    `
    const cardiac_def_html = `<div style="overflow-wrap: break-word;">
    An adverse event involving the heart, or the systemic or pulmonary vasculature that presents with  
    <ol class="f6 tl"> 
        <li> Cardiac arrest </li>
        <li> Hypo/hypertension </li>
        <li> Poor perfusion/Acidemia </li> 
    </ol>
    Common causes include
    <ol class="f6 tl"> 
    <li>Arrythmias</li>
    <li>Drug overdose</li>
    <li>Impaired venous/arterial flow</li>
    <li>Myocardial injury etc.</li>

    </div>`
    const neuro_def_html = `An adverse event involving central and/or peripheral nervous systems that presents with 
    <ol class="f6 tl">
    <li>Sensory disturbances</li>
    <li>Motor disturbances</li>
    <li>Loss of neuromonitoring signals</li>
    </ol>
    Common causes include 
    <ol class="f6 tl">
    <li>Spinal cord/peripheral nerve injury</li>
    <li>Stroke/herniation/coma</li>
    <li>Seizures/visual defects etc. </li>
    </ol>
    `
    const psych_def_html = `
    An adverse psychological event that presents with 
    <ol class="f6 tl">
    <li>change in behavior </li>
    <li>Mood changes</li>
    <li>cognition/memory changes</li>
    </ol>
    Common causes include 
    <ol class="f6 tl">
    <li>Awareness</li>
    <li>Delirium/hallucination</li>
    <li>Psychotic behavior</li>
    <li>Memory loss </li>
    </ol>

    `
    const dental_def_html =`
    Unanticipated dental injury which can lead to
    <ol class="f6 tl">
    <li>Broken or missing tooth </li>
    <li>Gingival injury </li>
    <li>Pain</li>
    </ol>
    Common causes include 
    <ol class="f6 tl">
    <li>Trauma </li>
    <li>Infection </li>
    <li>Ischemia </li>
    </ol>
    ` 

    const metabolic_def_html =`Metabolic adverse event that present with 
    <ol class="f6 tl">
    <li>Abnormal lab tests</li>
    <li> cardiovascular abnormalities </li>
    <li> Neurological/psychological abnormalities </li>
    </ol>
    Common causes include 
    <ul class="f6 tl">
    <li>Malignant Hyperthermia</li>
    <li>Hypoglycemia</li>
    <li>Acidosis/hyperkalemia</li>
    <li>Electrolyte abnormalities etc.</li>
    </ul>
    `
    const musculoskeletal_def_html =`An adverse event involving musculoskeletal or cutaneous systems except dental and eye injuries that 
    <ol class="f6 tl"> 
        <li>Physical deformity/defect</li>
        <li>pain</li>
        <li>movement deficit </li>
    </ol>
    Common causes include 
    <ol class="f6 tl"> 
    <li>Burns</li>
    <li>Trauma</li>
    <li>Pressure injury</li>
    <li>Extravasation etc </li>
    </ol>
    `
    const misc_def_html =`Any event that does not fall into previously listed categories that is related to
    <ol class="f6 tl"> 
        <li>Fire</li>
        <li>Wrong site procedure</li>
        <li>Wrong procedure</li>
        <li>Retained foreign body </li>
    </ol>
`;
    const options = [
        { label: "Eye Injury", value: "eye", file: "./eye.png", definition: eye_def_html},
        { label: "Airway", value: "airway", file: "airway.png", definition: airway_def_html },
        { label: "Cardiac", value: "cardiac", file: "heart.png", definition: cardiac_def_html},
        { label: "Neurologic", value: "neurology", file: "brain.png", definition: neuro_def_html},
        { label: "Psychological", value: "psych", file: "psych.png",definition: psych_def_html},
        { label: "Dental", value: "dental" , file: "tooth.png",definition: dental_def_html},
        { label: "Metabolic", value: "meta", file: "metabolic.png",definition:metabolic_def_html},
        { label: "Musculoskeletal", value: "musculoskeletal", file: "somatic.png", definition:musculoskeletal_def_html},
        { label: "Miscellaneous", value: "misc", file: "misc.png",definition:misc_def_html},
    ];

    return (
        <Menu options={options} />
    );





};

export default ClinicalEvents;
