import React, { FC, useEffect, useState } from "react";
import { Redirect } from "react-router";

import ReactDOM from "react-dom";
// import * as Survey from "survey-react";
import { StylesManager, Model } from "survey-core";
import { PageModel } from "survey-core";
import { Survey, SurveyQuestionMatrixDynamicRemoveButton } from "survey-react-ui";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";


import { composeJSON, pagesJSON } from "./survey_templates/SurveyComposer";
import { storeWebSession, storeSurveySession} from "./utility/DbService";
import { service_url } from "./config";
import { useSessionStorage } from './useSessionStorage';
import { useInterval } from "./useInterval";
import Swal from 'sweetalert2';
import DDButton from "./DropDownButton";
import "./css/tachyons.min.css"
import { unload_handler } from "./sessionUtilities";
import { v5 as uuidv5 } from 'uuid';
import { useAuthenticator } from "@aws-amplify/ui-react";
import ReactTooltip from 'react-tooltip';
import optionDescriptions from "./survey_templates/choiceDescription.json"
// import cloneDeep from 'lodash/cloneDeep';

//Import localization
import './index.css';
require('./index.css');

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

var surveyloadcounter = 0;
const LoadEditableSurvey: FC = props => {
    var retry_counter = 0;
    var ques_info_flag = false;
    // const location = useLocation();
    var selected:string[] = [];
    let query = useQuery();
    
    let history = useHistory();
    
    // const [surveyUUID, setSurveyUUID] = useSessionStorage('case_submit_flag', JSON.stringify(query.get("surveyUUID")) );
    const [caseSubmitFlag, setCaseSubmitFlag] = useSessionStorage('case_submit_flag', false);
    const [surveyPayload, setsurveyPayload] = useState(null);
    const [loadFlag, setFlag] = useState(false)
    const [incomingEvents, setIncomingEvents] = useState<string[] | null>(null);

    const surveyUUID = query.get("surveyUUID")
    const reviewType = query.get("type");
    var page_json_map: any = {
        "Neurologic": "neurology", "Psychological": "psych", "Airway/Respiratory": "airway", "Eye": "eye", "Cardiac": "cardiac",
        "Dental": "dental", "Metabolic": "meta", "Musculoskeletal": "musculoskeletal", "Miscellaneous": "misc"
    }
    var old_page_json_map: any = {
        "03": "neurology", "08": "psych", "00": "airway", "02": "eye", "05": "cardiac",
        "06": "dental", "04": "meta", "01": "musculoskeletal", "09": "misc"
    }
    // const surveyUUID = query.get("surveyUUID");

    const loadSurveyFromDB = (surveyUUID: string) => {
        
        // console.log(sessionStorage.getItem("survey_uuid"))
        // console.log(surveyUUID)
        var dataString = { 
            "surveyUUID": surveyUUID, 
            "reviewFlag": query.get("type")
        };
        var config = {
            headers: { 'Access-Control-Allow-Origin': '*' }
        };

        axios.post(service_url + "/surveysession/getSurvey", dataString, config)
            .then(function (response) {
                // console.log(response.data);
                setsurveyPayload(JSON.parse(response.data));
                setFlag(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    //Compose the Survey based on incoming events
    StylesManager.applyTheme("modern");
    var survey = new Model();
    var json: any
    var data: any;

    var incoming_events = []
    useEffect(() => {
        sessionStorage.setItem("survey_uuid", JSON.stringify(surveyUUID))
        // @ts-ignore
        loadSurveyFromDB(surveyUUID);
        
    }, []);

    // useEffect(() => {
    //     setIncomingEvents[incoming_events]

    // }, [surveyPayload]);

    // useEffect(() => {

        
    // }, [surveyPayload]);
    if (surveyPayload) {
        if ("pages" in surveyPayload) {

            // console.log("Loading pages", surveyPayload);
            //@ts-ignore
            // console.log(surveyPayload["pages"]);
            // console.log(typeof(JSON.parse(surveyPayload["pages"])));

            
            //@ts-ignore
            for (let i = 0; i < surveyPayload["pages"].length; i++) {
                if (surveyPayload["pages"][i] === 'Somatic') {
                    incoming_events.push(page_json_map["Musculoskeletal"])
                }
                else {
                    incoming_events.push(page_json_map[surveyPayload["pages"][i]])
                }
            }
            // console.log("Events:", incoming_events)
            json = composeJSON(incoming_events);
            data = surveyPayload["data"];
        }
        else {
            // @ts-ignore
            json = surveyPayload["questionnaire"];
            for (let p =0;p<json['pages']['elements'].length;p++){
                let ques_id = json['pages']['elements'][0]['name'].substring(0,2)
                incoming_events.push(old_page_json_map[ques_id])
            }
            console.log("Loading questionnaire");
            data = surveyPayload["data"];
        }
        survey.setJsonObject(json);
        survey.data = data;
        // setIncomingEvents(incoming_events)
    } 



    // let incoming_events: any = [];
    var render_counter = 0;
    surveyloadcounter += 1;

    //@ts-ignore
    // for (let [key, value] of Object.entries(selected)) {
    //     // @ts-ignore
    //     let temp: string = value['value']
    //     incoming_events.push(temp);
    // }

    

    //Set the previous selection based on incoming events  


    

    const removePages = (remove_pages: string[]) => {
        if (remove_pages.length === 0) return;
        console.log("REMOVING PAGES", remove_pages)
        var remove_pages_json: any = pagesJSON(remove_pages);
        for (let k = 0; k < remove_pages_json.length; k++) {
            // Rework this
            var removed_page_name = remove_pages_json[k]['name']
            console.log(removed_page_name)
            var page = survey.getPageByName(removed_page_name)
            var question_names: any = []
            page.questions.forEach((element: any) => {
                question_names.push(element.name)
            });
            console.log(question_names)
            //Clear page before removal
            let current_data = survey.data

            let new_data: any = {}
            for (let ques in current_data) {
                // console.log(typeof(ques),ques)
                if (question_names.includes(ques)) {
                    console.log("Deleting:", ques)
                    delete current_data[ques]
                }
            }
            // console.log(new_data);
            survey.data = current_data;
            survey.removePage(page)

            var removal_index = 0;
            for (let j = 0; j < json["pages"].length; j++) {
                if (json["pages"][j]["name"] === remove_pages_json[k]['name']) {
                    removal_index = j;
                    break
                }
            }
            json["pages"].splice(removal_index, 1)
        }
    }
    const addPages = (add_pages: string[]) => {
        // console.log("ADDING PAGES", add_pages)
        if (add_pages.length === 0) return;
        var new_pages_json: any = pagesJSON(add_pages);
        for (let k = 0; k < new_pages_json.length; k++) {

            var page = new PageModel()
            page.fromJSON(new_pages_json[k])
            // survey.createNewPage(new_pages_json[k]['name'])
            // page.fromJSON(new_pages_json[k])

            //
            survey.addPage(page, survey.pageCount - 1)
            // survey = cloneDeep(survey)
            //Change State : 
            // survey.render();
            json["pages"].splice(json["pages"].length - 1, 0, new_pages_json[k]);
        }
        //reset the dropdown location
    }
    const event_changes = (obj: any) => {
        // console.log(obj)
        addPages(obj["add"])
        removePages(obj["remove"])
    }

    function doOnCurrentPageChanged(options: any) {

        // @ts-ignore
        var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');

        for (var i = 0; i < elemets.length; i++) {
            var div = elemets[i];
            div.setAttribute("style", "font-size:13px");
        }

    }
    survey.completeText = "Submit";


    //Called everytime a survey page is rendered
    survey
        .onAfterRenderHeader
        .add((sender: any, option: any) => {
            render_counter += 1;
            console.log("RENDER", render_counter)


        });
    survey
        .onPageAdded
        .add((sender: any, option: any) => {

            console.log("PAGE_ADDED")

        });
    survey
        .onAfterRenderQuestion
        .add((sender: any, option: any) => {

            let page_name = option.question.page.name;
            let question_name = option.question.name
            
            if (question_name === "0624cfe0-8375-52b4-9076-71d701257e31" && page_name === 'Demographics' && !ques_info_flag) {
                console.log(option.question.name)
                let asa_ques_id_in_static = "0624cfe0-8375-52b4-9076-71d701257e31"
                let asa_message = "<a href='https://www.asahq.org/standards-and-guidelines/asa-physical-status-classification-system' target='_blank'>ASA Physical Status Classification</a>"
                
                //@ts-ignore
                var element: any = document.querySelector('[data-name="' + asa_ques_id_in_static + '"]')?.
                    querySelector('.sv-question__header')?.
                    querySelector('.sv-title')?.
                    querySelector('.sv-string-viewer')
                // var element: any = document.querySelector('[data-key="'+ asa_ques_id_in_static + '"]')?.
                //     querySelector('[class="sv-question sv-row__question"]')?.
                //     querySelector('[class="sv-question__header"]')?.
                //     querySelector('[class="sv-title sv-question__title"]')?.
                //     querySelector('[class="sv-string-viewer"]')
                
                var sup = document.createElement("sup");
                sup.innerHTML = '<button data-tip data-for="info-' + asa_ques_id_in_static + '" class="br-100 f6 ba b--blue bg-blue p-help white"> ? </button>';
                var span = document.createElement("span");
                span.innerHTML = "  ";
                span.id = "span-" + asa_ques_id_in_static;

                if(element){

                    element.appendChild(span);
                    element.appendChild(sup);
                    ReactDOM.render(<ReactTooltip delayHide={1000} id={"info-" + asa_ques_id_in_static} place="right" className="custom-color-no-arrow" backgroundColor="#EEDC82" effect="solid" html={true}> {'<span class="b ma0 pa0 tl black">' + asa_message + '</span>'}</ReactTooltip>, document.getElementById("span-" + asa_ques_id_in_static))
                        
                    
                    setTimeout(() => {
                        var tooltip: any = document.getElementById("info-" + asa_ques_id_in_static)
                        if (tooltip) {
                            tooltip.style.marginRight = "4em"
                        }
                    }, 600);
                    ques_info_flag = true;
                }
                else{
                    // console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]'))
                    // console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]')?.
                    //     querySelector('[class="sv-question sv-row__question"]'))
                    // console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]')?.
                    //     querySelector('[class="sv-question sv-row__question"]')?.
                    //     querySelector('[class="sv-question__header"]'))
                    // console.log(document.querySelector('[data-key="' + asa_ques_id_in_static + '"]')?.
                    //     querySelector('[class="sv-question sv-row__question"]')?.
                    //     querySelector('[class="sv-question__header"]')?.
                    //     querySelector('[class="sv-title sv-question__title"]'))
                    // console.log(element)

                    console.log(document.querySelector('[data-name="' + asa_ques_id_in_static + '"]'))
                    console.log(document.querySelector('[data-name="' + asa_ques_id_in_static + '"]')?.querySelector('.sv-question__header'))
                    console.log("Element still not found")
                        
                    
                }
                // console.log(element);

            }
            if (!(page_name in optionDescriptions))
                return
            //@ts-ignore
            for (let key in optionDescriptions[page_name][question_name]) {
                // try{
                // console.log(key);
                //@ts-ignore
                let message = optionDescriptions[page_name][question_name][key]
                var element: any = document.querySelector('input[value="' + key + '"]')?.parentNode?.querySelector('.sv-item__control-label')
                var sup = document.createElement("sup");
                sup.innerHTML = '<button data-tip data-for="info-' + key + '" class="br-100 f6 ba b--blue bg-blue p-help white"> ? </button>';
                var span = document.createElement("span");
                span.innerHTML = "  ";
                span.id = "span-" + key;
                // span.className="w-30 fl"
                if(element)
                {

                    element.appendChild(span);
                    element.appendChild(sup);
                    ReactDOM.render(<ReactTooltip delayHide={1000} id={"info-" + key} place="right" className="custom-color-no-arrow" backgroundColor="#EEDC82" effect="solid" html={true}> {'<span class="b ma0 pa0 tl black">' + message + '</span>'}</ReactTooltip>, document.getElementById("span-" + key))
                    setTimeout(() => {
                        var tooltip: any = document.getElementById("info-" + key)
                        if (tooltip) {
                            tooltip.style.marginRight = "4em"
                        }
                    }, 500);
                }
                else{
                    console.log(element)
                }

            }
        });

    const retry_func = () => {
        retry_counter += 1;
        document.body.style.cursor = 'default';
        if (retry_counter == 3) {
            Swal.fire({
                icon: 'error',
                title: 'We are sorry for the Inconvenience...',
                text: 'Survey Not Submitted. Max Retries exceeded',
                footer: '<a href="#">Contact Support</a>'
            }).then(() => {

                // window.location.replace("/");
                history.push('/')
                // return <Redirect to='/'/>
            })
        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'We are sorry for the Inconvenience...',
                text: 'Survey Not Submitted. Please Retry',
                // footer: '<a href="#">Contact Support</a>'
            }).then(() => {

            })
        }
    }
    //What happens while submitting the survey

    var data_submitted = false;
    survey.onComplete.add((sender: any, options: any) => {
        // alert("Done");                

        setTimeout(
            () => {
                history.push("/");
            }, 2000)
    });

    function showDescription(question: any) {

    }

    survey
        .onCompleting
        .add(function (sender: any, options: any) {
            if (data_submitted) {

                document.body.style.cursor = 'default';
                return //For the second call
            }
            // this.style.cursor = "wait";
            document.body.style.cursor = 'progress';
            options.allowComplete = false;



            let survey_events = json["pages"].map((page: any) => (page["name"]))
            survey_events.shift();
            survey_events.pop();
            let surveyObj = {
                surveyUUID: surveyUUID,
                pages: survey_events,
                data: survey.data
            };
            sessionStorage.setItem('case_submit_flag', JSON.stringify(true));
            sessionStorage.setItem('survey_payload', JSON.stringify(surveyObj));

            let wspromise = storeWebSession();
            let sspromise = storeSurveySession();
            // console.log(wspromise)
            wspromise?.then(() => {
                sspromise?.then(() => {
                    options.allowComplete = true;
                    console.log(surveyUUID)
                    console.log("Survey Complete Databse Dump")
                    data_submitted = true;
                    sender.doComplete();

                }).catch(() => {
                    retry_func();
                });
            }).catch(() => {
                retry_func();
            })

        })

    survey.onCurrentPageChanged.add((sender: any, options: any) => { doOnCurrentPageChanged(options) });

    // useEffect(() => {

    //     setCaseSubmitFlag(false);
    //     setFlag(true);

    // }, []);

    useInterval(() => {
        // console.log(surveyUUID)
        let survey_events = json["pages"].map((page: any) => (page["name"]))
        survey_events.shift();
        survey_events.pop();
        sessionStorage.setItem('survey_payload', JSON.stringify({
            surveyUUID: surveyUUID,
            pages: survey_events,
            data: survey.data
        }))
        //Call DB Service
        // console.log(surveyUUID, webSessionID);
        storeSurveySession();
        // console.log(survey_events)
    }, 10000);

    return(

        <>
        {!loadFlag && !surveyPayload && 
            <div> Loading Survey ...</div>
        }
        {loadFlag && surveyPayload && 
            <div className="fl w-100 pa3 tl"><Survey model={survey} />
            <DDButton id_str="add_event" event_changes={event_changes} original_events={incoming_events} />
            </div>
        }
        </>
    )

};
export default LoadEditableSurvey;
