import MultiSelect from "react-multiselect-button-dropdown";
import React, { FC,useEffect, useState } from "react";
import Swal from 'sweetalert2';

var event_list = [
    {label: 'Airway',name: 'airway',id: 'airway_cb',checked: false},
    {label: 'Eye',name: 'eye',id: 'eye_cb',checked: false},
    {label: 'Cardiac',name: 'cardiac',id: 'cardiac_cb',checked: false},
    {label: 'Neurology',name: 'neurology',id: 'neurology_cb',checked: false},
    {label: 'Psychological',name: 'psych',id: 'psych_cb',checked: false},
    {label: 'Dental',name: 'dental',id: 'dental_cb',checked: false},
    {label: 'Metabolic',name: 'meta',id: 'meta_cb',checked: false},
    { label: 'Musculoskeletal', name: 'musculoskeletal', id: 'musculoskeletal_cb',checked: false},
    {label: 'Miscellaneous',name: 'misc',id: 'misc_cb',checked: false}
]

var current_selection={}
// const position_event_menu = ()=>{
//     console.log("REPOSITIONED MENU")
//     const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
//     const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
//     var top,left,bottom,right;
//     var progress_bar = document.getElementsByClassName('sv_progress-buttons__list-container')[0];
//     var add_btn = document.getElementById('add_event');
//     top = progress_bar.getBoundingClientRect().top;
//     bottom = progress_bar.getBoundingClientRect().bottom;
//     left = progress_bar.getBoundingClientRect().left;
//     right = progress_bar.getBoundingClientRect().left;
//     let right_percent = (right/vw)*100
//     let height = (bottom-top);
//     add_btn?.setAttribute("style","position: absolute; left: "+(100-right_percent+2.5)+"%; top: "+(top + height/5)+"px");
//     document.getElementsByClassName("hnUIWU")[0].setAttribute("style","box-shadow:none")

// }

const addToProgressBar = ()=>{
    var progress_bar = document.getElementsByClassName('sv_progress-buttons__container-center')[0];
    var add_btn = document.getElementById('add_event');
    add_btn?.setAttribute("style","display: flex;");
    progress_bar.appendChild(add_btn)
    document.getElementsByClassName("hnUIWU")[0].setAttribute("style","box-shadow:none")

}

const defaultProps = {
    dropdownButtonText: 'Add/Delete Event',
    resetButtonText: 'Reset',
    applyButtonText: 'Apply',
    // isRightAligned: true
}; 

const getCurrentSelection=()=>{
    let ret_selection = {}
    for(let key in current_selection){
        ret_selection[key] = document.getElementById(key).checked;
    }
    return ret_selection;
}

const event_selection =(selection)=>{
    var add_pages=[];
    var remove_pages =[]
    // current_selection = getCurrentSelection();
    console.log(current_selection)
    //@ts-ignore
    console.log("Event_Selection",selection)
    for(var key in selection){
        if(current_selection[key]!=selection[key]){
            if (selection[key]){
                add_pages.push(key.split('_')[0]);
            }
            else{
                remove_pages.push(key.split('_')[0])
            }
        }
    }
    return {"add":add_pages,"remove":remove_pages}
}

const DDButton = props =>{
    // const [event_list,setEventList] = useState()
    console.log(props)
    // Initialize the menu as well as the current_selection object
    for(let k=0;k<event_list.length;k++){
        if(props['original_events'].includes(event_list[k]['name'])){
            event_list[k]["checked"]=true;
        }
        current_selection[event_list[k]['id']]=event_list[k]["checked"]
    }
    useEffect(()=>{
        // console.log("Event Menu re-Rendered")
        // setTimeout(position_event_menu(),500);
        setTimeout(addToProgressBar(),500)
    });
    // console.log("Current Selection:",current_selection)
    const selectionApplied = (selection)=>{
        //Close the menu
        let page_changes = event_selection(selection)
        console.log(page_changes);
        if(page_changes["remove"].length!=0){
            Swal.fire({
                icon: 'warning',
                title: 'Delete Event?',
                text: 'The data corresponding to the deleted event will be removed permanently',
                showCancelButton: true,
                confirmButtonText: 'Yes, Delete it!',
                confirmButtonColor: '#3EB489',
                cancelButtonColor: '#d33',
            }).then((result)=>{
                if (!result.isConfirmed){
                    console.log(current_selection)
                    for(let k=0;k<page_changes["remove"].length;k++){
                        document.getElementById(page_changes["remove"][k]+'_cb').click();
                    }
                    for(let k=0;k<page_changes["add"].length;k++){
                        document.getElementById(page_changes["add"][k]+'_cb').click();
                    }
                    
                }
                else{
                    console.log(current_selection)
                    current_selection = getCurrentSelection();
                    props['event_changes'](page_changes)
                }
                setTimeout(document.getElementsByClassName("multiselect-button-dropdown")[0].click(),500);
                // setTimeout(position_event_menu(),1500);
            });

        }
        else{
            
            current_selection = getCurrentSelection();
            props["event_changes"](page_changes)
            setTimeout(document.getElementsByClassName("multiselect-button-dropdown")[0].click(),500);
            // setTimeout(position_event_menu(),1500);
        }
        
    }
    // window.addEventListener('resize',position_event_menu);
    return <div className="fr w-20" id={props['id_str']}><MultiSelect id={props['id_str']} list={event_list} onSelectionApplied={selectionApplied} {...defaultProps}/></div>
}

export default DDButton;    


// const upsert_elem = (array: string[], element: any) => {
//     const i = array.indexOf(element);
//     if (i > -1) array[i] = element; 
//     else array.push(element);
//     return array
// }
// const remove_elem = (array: string[], element: any) =>{
//     const i = array.indexOf(element);
//     if (i > -1) { 
//         array.splice(i, 1);
//     }
//     return array
// }

