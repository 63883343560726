import React, {FC} from "react";
import ReactDOM from "react-dom";

import 'select2/dist/css/select2.min.css';

import "survey-core/modern.css";
import "./index.css";
// @ts-ignore
// @ts-ignore
// import { createRoot } from 'react-dom/client';
import SurveyComponent from './SurveyComponent';

const Demographics: FC = props =>   {
    // select2Init();
    //
    // let json = {
    //     "title": "Survey",
    //     "description": "Patient Demographics",
    //     "logo": "http://localhost:3000/wake_up_safe_logo.png",
    //     "logoWidth": "200px",
    //     "logoHeight": "90px",
    //     "logoPosition": "right",
    //     "pages": [
    //         {
    //             "name": "Demographics",
    //             "elements": [
    //                 {
    //                     "type": "tagbox",
    //                     "isRequired": true,
    //                     "choicesByUrl": {
    //                         "url": "https://surveyjs.io/api/CountriesExample"
    //                     },
    //                     "name": "countries",
    //                     "title": "Please select all countries you have been for the last 3 years."
    //                 },
    //                 {
    //                     "type": "text",
    //                     "name": "1001",
    //                     "title": "1. Patient’s Diagnoses (ICD 10 code)",
    //                     "hideNumber": true
    //                 }
    //             ]
    //         }
    //     ]
    // };
    //
    // // @ts-ignore
    // window["$"] = window["jQuery"] = $;
    //
    //
    // StylesManager.applyTheme("modern");
    // widgets.select2(SurveyCore);
    // widgets.select2tagbox(SurveyCore);
    //
    // Survey
    //     .StylesManager
    //     .applyTheme("modern");
    //
    // var survey = new Survey.Model(json);
    //
    // survey.completeText = "Next";
    //
    // survey
    //     .onComplete
    //     .add(function (sender) {
    //         // @ts-ignore
    //         document.documentElement.querySelector('#surveyResult').textContent="Result JSON:\n" + JSON.stringify(sender.data, null, 3);
    //
    //         // send data to backend database
    //         var config = {
    //             headers: {'Access-Control-Allow-Origin': '*'}
    //         };
    //         axios.post('http://localhost:5001/', sender.data, config)
    //             .then(function(response){
    //                 console.log(response);
    //                 //Perform action based on response
    //             })
    //             .catch(function(error){
    //                 console.log(error);
    //                 //Perform action based on error
    //             });
    //         // route to clinical events selection page
    //         window.location.href = "/select_clinical_events";
    //     });
    //
    // function doOnCurrentPageChanged() {
    //
    //     // // @ts-ignore
    //     // var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');
    //     //
    //     // for(var i=0; i< elemets.length; i++){
    //     //     var div = elemets[i];
    //     //     div.setAttribute("style","font-size:13px");
    //     // }
    // }

    // ReactDOM.render(<Survey.Survey model={survey}/>, document.getElementById("root"), function(){doOnCurrentPageChanged()});
    // const root = createRoot(document.getElementById("root"));
    // root.render(<SurveyComponent />);
    ReactDOM.render(<SurveyComponent />, document.getElementById("root"));
    return (
        <React.Fragment>
        </React.Fragment>
    );
};

export default Demographics;