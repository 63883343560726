// export const json = {
//     "elements": [
//         {
//             "type": "checkbox",
//             "name": "car",
//             "title": "What cars have you drived?",
//             "isRequired": true,
//             "hasNone": true,
//             "choices": [
//                 "Ford",
//                 "Vauxhall",
//                 "Volkswagen",
//                 "Nissan",
//                 "Audi",
//                 "Mercedes-Benz",
//                 "BMW",
//                 "Peugeot",
//                 "Toyota",
//                 "Citroen"
//             ],
//             "renderAs": "tagbox-react"
//         }
//     ]
// };

export const json = {
    "title": "Survey",
    "description": "Patient Demographics",
    "logo": "http://localhost:3000/wake_up_safe_logo.png",
    "logoWidth": "200px",
    "logoHeight": "90px",
    "logoPosition": "right",
    "pages": [
        {
            "name": "Demographics",
            "elements": [
                {
                    "type": "text",
                    "name": "1001",
                    "title": "1. Patient’s Diagnoses (ICD 10 code)",
                    "hideNumber": true
                },
                {
                    "type": "checkbox",
                    "name": "car",
                    "title": "What cars have you drived?",
                    "isRequired": true,
                    "hasNone": true,
                    "choicesByUrl": {
                        "url": "https://surveyjs.io/api/CountriesExample"
                    },
                    "renderAs": "tagbox-react"
                },
                {
                    "type": "paneldynamic",
                    "name": "question38",
                    "visibleIf": "{1002} = 'true'",
                    "title": "  ",
                    "hideNumber": true,
                    "templateElements": [
                        {
                            "type": "text",
                            "name": "100201",
                            "title": "Add a procedure code or description",
                            "hideNumber": true
                        }
                    ],
                    "panelCount": 1,
                    "minPanelCount": 1,
                    "confirmDelete": true
                },
                {
                    "type": "text",
                    "name": "1003",
                    "title": "3. Event Year (Year event occurred) ",
                    "hideNumber": true
                },
                {
                    "type": "radiogroup",
                    "name": "1004",
                    "title": "4. Day event occurred ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1004_01",
                            "text": "Monday-Friday"
                        },
                        {
                            "value": "1004_02",
                            "text": "Saturday-Sunday-holiday"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1005",
                    "title": "5. Event Time (military time - 0000 to 2400) ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1005_01",
                            "text": "0700-1700"
                        },
                        {
                            "value": "1005_02",
                            "text": "1700-0700/Off Hours"
                        }
                    ]
                },
                {
                    "type": "text",
                    "name": "1006",
                    "title": "6. Length of anesthesia procedure in minutes ",
                    "description": "mins",
                    "descriptionLocation": "underInput",
                    "hideNumber": true
                },
                {
                    "type": "text",
                    "name": "1007",
                    "title": "7. Patient’s weight (In kilograms)",
                    "description": "kg\n",
                    "descriptionLocation": "underInput",
                    "hideNumber": true
                },
                {
                    "type": "text",
                    "name": "1008",
                    "title": "8. Body Mass Index (BMI)",
                    "hideNumber": true
                },
                {
                    "type": "radiogroup",
                    "name": "1009",
                    "title": "9. Patient’s race",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1009_01",
                            "text": "White or Caucasian"
                        },
                        {
                            "value": "1009_02",
                            "text": "Black or African American"
                        },
                        {
                            "value": "1009_03",
                            "text": "Asian"
                        },
                        {
                            "value": "1009_04",
                            "text": "Native Hawaiian or Pacific Islander"
                        },
                        {
                            "value": "1009_05",
                            "text": "American Indian or Alaskan native"
                        },
                        {
                            "value": "1009_06",
                            "text": "More than one race"
                        }
                    ],
                    "hasOther": true,
                    "colCount": 2
                },
                {
                    "type": "radiogroup",
                    "name": "f678a0b2-cd4f-11ec-9d64-0242ac120002",
                    "title": "10. Patient's ethnic group",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "f678a0b2-cd4f-11ec-9d64-0242ac120002_01",
                            "text": "Hispanic or Latino"
                        },
                        {
                            "value": "f678a0b2-cd4f-11ec-9d64-0242ac120002_02",
                            "text": "Not Hispanic or Latino"
                        },
                        {
                            "value": "f678a0b2-cd4f-11ec-9d64-0242ac120002_03",
                            "text": "Unknown"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1010",
                    "title": "10. Patient’s Sex",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1010_01",
                            "text": "Male"
                        },
                        {
                            "value": "1010_02",
                            "text": "Female"
                        }
                    ],
                    "hasOther": true
                },
                {
                    "type": "radiogroup",
                    "name": "1012",
                    "title": "12. Payor",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1012_01",
                            "text": "Private Insurance"
                        },
                        {
                            "value": "1012_02",
                            "text": "Public Insurance"
                        },
                        {
                            "value": "1012_03",
                            "text": "Uninsured"
                        }
                    ]
                },
                {
                    "type": "multipletext",
                    "name": "1012",
                    "title": "12. Patient Age",
                    "hideNumber": true,
                    "items": [
                        {
                            "name": "text1",
                            "title": "Year"
                        },
                        {
                            "name": "text2",
                            "title": "Month"
                        }
                    ],
                    "itemSize": 50,
                    "colCount": 2
                },
                {
                    "type": "text",
                    "name": "1013",
                    "title": "13. What was gestational age (at birth) in weeks?",
                    "hideNumber": true
                },
                {
                    "type": "radiogroup",
                    "name": "1014",
                    "title": "14. Prior to the procedure what was the patient’s ASA Status",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1014_01",
                            "text": "Class 1 – normal healthy patient"
                        },
                        {
                            "value": "1014_02",
                            "text": "Class 2 – patient with mild systemic disease"
                        },
                        {
                            "value": "1014_03",
                            "text": "Class 3 – patient with severe systemic disease"
                        },
                        {
                            "value": "1014_04",
                            "text": "Class 4 – patient with severe systemic disease that is a constant threat to life"
                        },
                        {
                            "value": "1014_05",
                            "text": "Class 5 – moribund patient who is not expected to survive without the operation"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1015",
                    "title": "15. Was the procedure an ASA status E case?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1015_01",
                            "text": "No"
                        },
                        {
                            "value": "1015_02",
                            "text": "Yes"
                        }
                    ]
                },
                {
                    "type": "dropdown",
                    "name": "1016",
                    "title": "16. Where did the event occur? ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1016_01",
                            "text": "OR"
                        },
                        {
                            "value": "1016_02",
                            "text": "NORA"
                        },
                        {
                            "value": "1016_03",
                            "text": "ICU"
                        },
                        {
                            "value": "1016_04",
                            "text": "Inpatient Ward"
                        },
                        {
                            "value": "1016_05",
                            "text": "Home"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "panel2",
                    "elements": [
                        {
                            "type": "radiogroup",
                            "name": "101601",
                            "visibleIf": "{1016} = '1016_01'",
                            "indent": 2,
                            "title": "16.1 OR",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "101601_01",
                                    "text": "Preop"
                                },
                                {
                                    "value": "101601_02",
                                    "text": "Intraop"
                                }
                            ],
                            "hasOther": true
                        },
                        {
                            "type": "radiogroup",
                            "name": "101602",
                            "visibleIf": "{1016} = '1016_02'",
                            "indent": 2,
                            "title": "16.2 NORA",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "101602_01",
                                    "text": "Diagnostic Radiology (CT or MRI or PET from anesthesia standpoint may not be a huge difference)"
                                },
                                {
                                    "value": "101602_02",
                                    "text": "Interventional Radiology"
                                },
                                {
                                    "value": "101602_03",
                                    "text": "GI Suite"
                                },
                                {
                                    "value": "101602_04",
                                    "text": "Cardiac Suite"
                                }
                            ],
                            "hasOther": true
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1017",
                    "title": "17. Where did the patient come from on the day of service?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1017_01",
                            "text": "Home"
                        },
                        {
                            "value": "1017_02",
                            "text": "Residential Care Facility"
                        },
                        {
                            "value": "1017_03",
                            "text": "Inpatient ward"
                        },
                        {
                            "value": "1017_04",
                            "text": "ICU"
                        },
                        {
                            "value": "1017_05",
                            "text": "Emergency Department"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1018",
                    "title": "18. What was the planned post-operative disposition?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1018_01",
                            "text": "Home"
                        },
                        {
                            "value": "1018_02",
                            "text": "Residential Care Facility"
                        },
                        {
                            "value": "1018_03",
                            "text": "Inpatient ward"
                        },
                        {
                            "value": "1018_04",
                            "text": "ICU"
                        },
                        {
                            "value": "1018_05",
                            "text": "Step-down unit"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1019",
                    "title": "19. When did the event or injury occur?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1019_01",
                            "text": "Preoperative"
                        },
                        {
                            "value": "1019_02",
                            "text": "Intraoperative"
                        },
                        {
                            "value": "1019_03",
                            "text": "Postoperative"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "panel3",
                    "elements": [
                        {
                            "type": "radiogroup",
                            "name": "101901",
                            "title": "19.1 Which Postoperative",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "101901_01",
                                    "text": "PACU"
                                },
                                {
                                    "value": "101901_02",
                                    "text": "Ward"
                                },
                                {
                                    "value": "101901_03",
                                    "text": "ICU"
                                },
                                {
                                    "value": "101901_04",
                                    "text": "Home"
                                },
                                {
                                    "value": "101901_05",
                                    "text": "Residential facility"
                                }
                            ]
                        }
                    ],
                    "visibleIf": "{1019} = '1019_03'",
                    "innerIndent": 2
                },
                {
                    "type": "checkbox",
                    "name": "1020",
                    "title": "20. Type of Anesthesia (check all that apply)",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1020_01",
                            "text": "General"
                        },
                        {
                            "value": "1020_02",
                            "text": "Regional"
                        },
                        {
                            "value": "1020_03",
                            "text": "Neuraxial"
                        },
                        {
                            "value": "1020_04",
                            "text": "Monitored anesthesia care (Anesthesia)"
                        },
                        {
                            "value": "1020_05",
                            "text": "No anesthetic"
                        }
                    ]
                },
                {
                    "type": "checkbox",
                    "name": "1021",
                    "title": "21. Primary maintenance drug for general or sedation if used at time of event (Choose all that apply) ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1021_01",
                            "text": "Sevoflurane/Isoflurane/Desflurane"
                        },
                        {
                            "value": "1021_02",
                            "text": "Propofol"
                        },
                        {
                            "value": "1021_03",
                            "text": "Ketamine"
                        },
                        {
                            "value": "1021_04",
                            "text": "Dexmedetomidine"
                        },
                        {
                            "value": "1021_05",
                            "text": "Midazolam"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1022",
                    "title": "22. Muscle relaxants used",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1022_01",
                            "text": "Non-Depolarizer"
                        },
                        {
                            "value": "1022_02",
                            "text": "Succinylcholine"
                        }
                    ],
                    "hasNone": true
                },
                {
                    "type": "checkbox",
                    "name": "1023",
                    "title": "23. Other medications for the anesthetic or sedation (check all that apply) ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1023_01",
                            "text": "Opioids"
                        },
                        {
                            "value": "1023_02",
                            "text": "Benzodiazepines"
                        },
                        {
                            "value": "1023_03",
                            "text": "Barbiturates"
                        },
                        {
                            "value": "1023_04",
                            "text": "Ketamine"
                        },
                        {
                            "value": "1023_05",
                            "text": "Alpha adrenergics"
                        },
                        {
                            "value": "1023_06",
                            "text": "Propofol"
                        },
                        {
                            "value": "1023_07",
                            "text": "Etomidate"
                        },
                        {
                            "value": "1023_08",
                            "text": "Local anesthetics"
                        }
                    ],
                    "hasNone": true
                },
                {
                    "type": "radiogroup",
                    "name": "1024",
                    "title": "24. Patient position at time of event",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1024_01",
                            "text": "Supine"
                        },
                        {
                            "value": "1024_02",
                            "text": "Beach chair"
                        },
                        {
                            "value": "1024_03",
                            "text": "Lateral"
                        },
                        {
                            "value": "1024_04",
                            "text": "Lithotomy"
                        },
                        {
                            "value": "1024_05",
                            "text": "Prone"
                        },
                        {
                            "value": "1024_06",
                            "text": "Reverse Trendelenburg"
                        },
                        {
                            "value": "1024_07",
                            "text": "Sitting"
                        },
                        {
                            "value": "1024_08",
                            "text": "Trendelenburg"
                        }
                    ],
                    "hasOther": true
                },
                {
                    "type": "radiogroup",
                    "name": "1025",
                    "title": "25. If the initial event occurred in the OR, who was hands-on anesthesia provider at the time of the event? ",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1025_01",
                            "text": "Anesthesia Assistant or Nurse Anesthetist"
                        },
                        {
                            "value": "1025_02",
                            "text": "Student nurse anesthetist"
                        },
                        {
                            "value": "1025_03",
                            "text": "Anesthesia Resident"
                        },
                        {
                            "value": "1025_04",
                            "text": "Anesthesia Fellow"
                        },
                        {
                            "value": "1025_05",
                            "text": "Attending Anesthesiologist (pediatric)"
                        },
                        {
                            "value": "1025_06",
                            "text": "Attending Anesthesiologist (not pediatric)"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1026",
                    "title": "26. How was the case managed?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1026_01",
                            "text": "Anesthesiology Solo"
                        },
                        {
                            "value": "1026_02",
                            "text": "Anesthesiology Directed Care"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1027",
                    "title": "27. At 24 hours after anesthesia, what was the outcome to the patient? Check all applicable categories.",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1027_01",
                            "text": "Death.  Dead at time of assessment "
                        },
                        {
                            "value": "1027_02",
                            "text": "Permanent harm:  Lifelong bodily or psychological injury or increased susceptibility to disease.  Prognosis from time of assessment. "
                        },
                        {
                            "value": "1027_03",
                            "text": "Temporary harm:  Bodily or psychological injury, but likely not permanent.  Prognosis from time of assessment. "
                        },
                        {
                            "value": "1027_04",
                            "text": "No harm"
                        }
                    ]
                },
                {
                    "type": "radiogroup",
                    "name": "1028",
                    "title": "28. What was the escalation of care due to the event?    (Exclude change of surgery, wrong scheduling etc.)",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1028_01",
                            "text": "Additional treatment by anesthesia team (e.g., medications, procedures)"
                        },
                        {
                            "value": "1028_02",
                            "text": "Perioperative response team activation"
                        },
                        {
                            "value": "1028_03",
                            "text": "Prolongation PACU time"
                        },
                        {
                            "value": "1028_04",
                            "text": "Admit to ward instead of home"
                        },
                        {
                            "value": "1028_05",
                            "text": "Admit to ICU instead of ward"
                        }
                    ]
                },
                {
                    "type": "dropdown",
                    "name": "1029",
                    "title": "29. Was the event related to",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1029_01",
                            "text": "Anesthesia Primary"
                        },
                        {
                            "value": "1029_02",
                            "text": "Anesthesia Secondary"
                        }
                    ]
                },
                {
                    "type": "panel",
                    "name": "panel1",
                    "elements": [
                        {
                            "type": "radiogroup",
                            "name": "102901",
                            "visibleIf": "{1029} = '1029_01'",
                            "title": "29.1 Which Anesthesia Primary",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "102901_01",
                                    "text": "Surgical Secondary"
                                },
                                {
                                    "value": "102901_02",
                                    "text": "Patient Disease Secondary"
                                },
                                {
                                    "value": "102901_03",
                                    "text": "Nursing Secondary"
                                },
                                {
                                    "value": "102901_04",
                                    "text": "No Seconday"
                                }
                            ]
                        },
                        {
                            "type": "radiogroup",
                            "name": "102902",
                            "visibleIf": "{1029} = '1029_02'",
                            "title": "29.2 Which Anesthesia Secondary",
                            "hideNumber": true,
                            "choices": [
                                {
                                    "value": "102902_01",
                                    "text": "Surgical Primary"
                                },
                                {
                                    "value": "102902_02",
                                    "text": "Patient Disease Primary"
                                },
                                {
                                    "value": "102902_03",
                                    "text": "Nursing Primary"
                                }
                            ]
                        }
                    ],
                    "innerIndent": 2
                },
                {
                    "type": "text",
                    "name": "1030",
                    "title": "30. Please give a brief narrative summary of what happened & how was the event managed?  ",
                    "hideNumber": true,
                    "maxLength": 2000,
                    "placeHolder": "Free text (max 2000 characters) "
                },
                {
                    "type": "checkbox",
                    "name": "1031",
                    "title": "31. What type of event or injury occurred?",
                    "hideNumber": true,
                    "choices": [
                        {
                            "value": "1031_01",
                            "text": "Airway/Respiratory Event"
                        },
                        {
                            "value": "1031_02",
                            "text": "Cardiac/Cardiovascular Event"
                        },
                        {
                            "value": "1031_03",
                            "text": "Dental/Oral\tEvent "
                        },
                        {
                            "value": "1031_04",
                            "text": "Eye/Vision Event"
                        },
                        {
                            "value": "1031_05",
                            "text": "Metabolic Event"
                        },
                        {
                            "value": "1031_06",
                            "text": "Miscellaneous Events"
                        },
                        {
                            "value": "1031_07",
                            "text": "Neurologic Event"
                        },
                        {
                            "value": "1031_08",
                            "text": "Psychological Event"
                        },
                        {
                            "value": "1031_09",
                            "text": "Skin and Musculoskeletal event"
                        }
                    ]
                }
            ]
        }
    ]
};
