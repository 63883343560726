import React, {FC, useEffect, useState} from "react";
// @ts-ignore
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
// @ts-ignore
import paginationFactory from 'react-bootstrap-table2-paginator';
// @ts-ignore
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import {service_url} from "./config";
import { Button } from "react-bootstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import "./css/tachyons.min.css"
import { useHistory } from "react-router";
import dayjs from "dayjs";

const ReviewComp = (props:any) =>{
    let history = useHistory();
    // console.log(props.create_date, typeof(props.create_date))
    return (
        <div className="flex justify-center w-100 pa1 items-center">
            <Button variant="primary" onClick={()=>{
                history.push('/' + "single_case?surveyUUID=" + props.id + "&type=" + props.type)        
            }}>Review</Button>
            {props.super_user === false && props.duration_days<=30 && props.type==='submitted' && <Button className = "ml3" variant="danger" onClick={() => {
                history.push('/' + "edit_single_case?surveyUUID=" + props.id + "&type=" + props.type)
            }}>Edit</Button>}
            {props.super_user === false && props.type === 'unsubmitted' && <Button className="ml3" variant="danger" onClick={() => {
                history.push('/' + "edit_single_case?surveyUUID=" + props.id + "&type=" + props.type)
            }}>Edit</Button>}
        </div>
    )
}

// const EditComp = (props: any) => {
//     let history = useHistory();
//     return (
//         <div className="flex justify-center w-100 pa1 items-center">
//             <Button variant="danger" onClick={() => {
//                 history.push(/' + "single_case?surveyUUID=" + props.id)
//             }}>Edit</Button>
//         </div>
//     )
// }
function TableComponent(props: any){

    let history = useHistory();
    const tableData = props.data;
    const columns = props.columns;
    const type_of_case = props.type
    const { SearchBar, ClearSearchButton } = Search;
    const defaultSorted = [{
        dataField: 'create_dt',
        order: 'desc'
    }];

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 5,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        alwaysShowAllBtns: true
    });

    const tableRowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            console.log(`clicked on row with index: ${rowIndex}`);
            // console.log(row["id"]);
            // history.push('/'+"single_case?surveyUUID="+row["id"])
            // window.location.href = "single_case?surveyUUID="+row["id"];
        },
        onMouseEnter: (e: any, row: any, rowIndex: any) => {
            document.body.style.cursor = 'text';
        },
        onMouseLeave: (e: any, row: any, rowIndex: any) => {
            document.body.style.cursor = 'default';
        }
    }

    return (
        <div className="App">
            {/* <BootstrapTable bootstrap4 keyField='id' data={tableData} columns={columns} defaultSorted={defaultSorted} pagination={pagination} /> */}
            <div className="pa2">
                <ToolkitProvider
                    bootstrap4
                    keyField='id'
                    data={tableData}
                    columns={columns}
                    search
                >
                    {
                        (props: { searchProps: JSX.IntrinsicAttributes; baseProps: JSX.IntrinsicAttributes; }) => (
                            <div>
                                <Link to="/">Go back to main page</Link>
                                <h6>{"Search for your " + type_of_case +" cases:"} </h6>
                                <SearchBar {...props.searchProps} />
                                <ClearSearchButton {...props.searchProps} />
                                <hr />
                                <BootstrapTable
                                    defaultSorted={defaultSorted}
                                    pagination={pagination}
                                    rowEvents={ tableRowEvents }
                                    {...props.baseProps}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>

        </div>);

}


const ReviewCases: FC<any>= props => {

    var submitFlag: boolean = props.submitted;
    
    // const [surveyList, setSurveyList] = useState(null);
    const[loadFlag, setFlag] = useState(false)
    const[surveyList, setList] = useState([])
    // var surveyList: any = [];
    const [search_id, setSearchID] = useState(sessionStorage.getItem('user_name'));
    // @ts-ignore
    var userName = search_id.replaceAll('"', "");

    const getAllSurveys = (name: string) => {
        var offset = new Date().getTimezoneOffset();
        var dataString = {
            "UserName": name, 
            "submitFlag": submitFlag, 
            "offset": offset,
            "reviewFlag": props.type
        };
        var config = {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        };
    
        axios.post(service_url + "/surveysession/getUserSurveys", dataString, config)
            .then((response) => {
                setList(response.data);
                setFlag(true);
                console.log(response.data)
            }, (error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        // console.log(submitFlag)
        let isMounted = true;
        if(isMounted){

            getAllSurveys(userName);
        }
        return () => { isMounted = false }; 

    },[search_id]);


    

    const columns = [
        { 
            dataField: 'review', 
            text: 'Review', 
            sort: false, 
            isDummyField: true,
            csvExport:false,
            formatter: (cell: any, row:any) => {return <ReviewComp id={row.id} type={props.type} duration_days = {row.create_duration_days} super_user={row.request_user_role===0}/>} 
        },
        {dataField: 'id', text: 'Case ID', sort: true, events: {}},
        { dataField: 'user', text: 'Latest Editor', sort: true},
        {
            dataField: 'initial_user', 
            text: 'Original Submittor', 
            sort: true,
        },
        { dataField: 'create_dt', text: 'Original Submission Timestamp', sort: true,
            //@ts-ignore 
            sortFunc: (a, b, order, dataField, rowA, rowB) => {
                console.log(a,b)
                if (order === 'asc') {
                    return dayjs(a, "MM/DD/YYYY, HH:mm:ss").isBefore(dayjs(b, "MM/DD/YYYY, HH:mm:ss"))?-1:1
                }
                else {
                    return dayjs(b, "MM/DD/YYYY, HH:mm:ss").isBefore(dayjs(a, "MM/DD/YYYY, HH:mm:ss"))?-1:1
                }
            } 
        },
        { dataField: 'update_dt', text: 'Last Edit Timestamp', sort: true },
        // { dataField: 'submit_dt', text: 'Case Submitted Timestamp', sort: true },
        { dataField: 'event_type', text: 'Events', sort: true, style:{whiteSpace: 'pre' } },
    ];





    // @ts-ignore
    if (!loadFlag)
    {
        return (<div className="App"> Loading Results ...</div>)
    }
    return(
        <TableComponent data={surveyList} columns={columns} type={props.type}/>
        );


};

export default ReviewCases;
