import React, {FC, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { StylesManager, Model } from "survey-core";
import { Survey } from "survey-react-ui";
import {useLocation} from "react-router";
import axios, {AxiosRequestConfig} from "axios";
import './index.css';
import {service_url} from "./config";
import { useHistory } from "react-router-dom";
import { composeJSON, pagesJSON }  from "./survey_templates/SurveyComposer";
// import {loadSurvey, sendDataToDataAPI} from "./utility/DbService";
require('./index.css');

function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const LoadSingleSurvey: FC = props => { 
    var page_json_map:any ={"Neurologic":"neurology", "Psychological": "psych","Airway/Respiratory":"airway","Eye":"eye","Cardiac":"cardiac",
        "Dental": "dental", "Metabolic": "meta", "Musculoskeletal":"musculoskeletal","Miscellaneous":"misc"}
    let query = useQuery();
    let history = useHistory();
    const surveyUUID = query.get("surveyUUID");
    // const reviewType = query.get("type");

    const[loadFlag, setFlag] = useState(false);
    const[surveyPayload, setsurveyPayload] = useState(null);

    useEffect(()=>{
        // @ts-ignore
        loadSurveyFromDB(surveyUUID);
    }, []);

    const loadSurveyFromDB = (surveyUUID: string) => {
        var dataString = {
            "surveyUUID": surveyUUID,
            "reviewFlag": query.get("type")
         };
        var config = {
            headers: {'Access-Control-Allow-Origin': '*'}
        };

        axios.post(service_url+"/surveysession/getSurvey",dataString,config)
            .then(function(response){
                // console.log(response.data);
                setsurveyPayload(JSON.parse(response.data));
                setFlag(true);
            })
            .catch(function(error){
                console.log(error);
            });

    }

    // @ts-ignore
    if (!loadFlag)
    {
        return (<div className="App"> Loading Results ...</div>)
    }


    StylesManager
        .applyTheme("modern");

    var survey = new Model();
    // @ts-ignore

    var json: any;
    if(surveyPayload){
        if("pages" in surveyPayload){
            
            //@ts-ignore
            console.log(surveyPayload["pages"]);
            let events:string[] = []
            //@ts-ignore
            for(let i=0;i<surveyPayload["pages"].length;i++){
                if (surveyPayload["pages"][i] === 'Somatic'){
                    events.push(page_json_map["Musculoskeletal"])
                }
                else{
                    events.push(page_json_map[surveyPayload["pages"][i]])
                }
            }
            console.log("Events:",events)
            json = composeJSON(events);
        }
        else{
            // @ts-ignore
            json = surveyPayload["questionnaire"];
            console.log("Loading questionnaire");
        }
        
    }
    // var json = 
    // @ts-ignore
    var data = surveyPayload["data"];

    
    console.log("Loaded Survey",json);
    survey.setJsonObject(json);
    survey.data = data;

    //@ts-ignore
    // if(!editMode)
    survey.mode ="display"
    survey.completeText = "Finish";

    // functions run on survey complete
    survey
        .onComplete
        .add(function (sender: any) {
            // @ts-ignore
            document.documentElement.querySelector('#surveyResult').textContent="Result JSON:\n" + JSON.stringify(sender.data, null, 3);

            // send data to backend database
            // var surveyObj = {questionnair: json, data: survey.data};
            // sendDataToDataAPI(surveyObj);

            // route to contributory page
            // window.location.href = "/";
            history.push('/')

        });

    function doOnCurrentPageChanged() {

        // @ts-ignore
        var elemets = document.getElementsByClassName('sv_progress-buttons__page-title');

        for(var i=0; i< elemets.length; i++){
            var div = elemets[i];
            div.setAttribute("style","font-size:13px");
        }

    }

    survey.onCurrentPageChanged.add(()=>{doOnCurrentPageChanged()})

    return (
        <div className="tl fl"><Survey model={survey}/></div>
    );

};

export default LoadSingleSurvey;
